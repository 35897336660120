<template>
  <div class="col-sm-12 col-2xl-10 px-0 fin-query ">
    <b-card class="page-card">
      <div slot="header">
        <b-row>
          <b-col cols="11">
            <div class="page-header-left advanced_query_home">
              <div class="pr-1 header-icon">
                <i
                  class="fa fa-download card-icon-font"
                  v-if="isBulkDownload"
                ></i>
                <i class="cuis-filter card-icon-font" v-else></i>
              </div>
              <div>
                <h5 class="mb-0" v-if="isBulkDownload">FIN CRM</h5>
                <h5 class="mb-0" v-else>
                  <b>{{ headerText }}</b>
                  <span v-if="isToolsPage">
                    Conference Import

                    <sup>
                      <i
                        class="icon-info icons font-weight-bold cursor-pointer"
                        @click="$refs[`info-ConferenceImport`].show()"
                      ></i>
                    </sup>
                  </span>
                </h5>
              </div>
              <div class="report-title pl-1 pt-1" v-if="canShowMbc">
                <multiselect
                  :allowEmpty="false"
                  :showLabels="false"
                  :options="companyTypes"
                  :multiple="false"
                  :close-on-select="true"
                  :searchable="false"
                  class="min-width-20 cursor-pointer"
                  @close="setCompanyType()"
                  v-model="selectedCompanyType"
                >
                </multiselect>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-modal
        size="lg"
        :ref="`info-ConferenceImport`"
        class="info-modal"
        title="Conference Import"
        ok-only
      >
        <div>
          <p>
            The Conference Import tool will enhance a contact list from a
            conference to make it actionable for sales and marketing efforts.
          </p>
          <p>Once processed, your contact list will be enriched with:</p>
          <ul>
            <li>Financial Institution Identifiers</li>
            <li>Contact Emails</li>
            <li>Contact LinkedIn</li>
            <li>Contact Phone</li>
            <li>Standardized Titles &amp; Roles</li>
          </ul>
          <p>
            In addition, a Query will be created in FIN Query with the financial
            institutions from the list. This will allow you to create a custom
            report in FIN Query will additional data elements like
            Technographics and Financial Metrics for each bank or credit union
            at the conference.
          </p>
        </div>
      </b-modal>
      <div
        class="row justify-content-center"
        v-if="!isToolsPage && !isBulkDownload"
      >
        <div class="col-sm-10" v-if="isMortgageLender">
          <FinQueryTopPanelForMortgageLenders></FinQueryTopPanelForMortgageLenders>
        </div>
        <div class="col-sm-10" v-else>
          <div class="row justify-content-center no-gutters">
            <router-link
              tag="div"
              :class="[
                isFIContactsFiltered && fisWithContactsCount
                  ? 'col-sm-4'
                  : 'col-sm-3',
                'd-flex p-2 std-border segment-tab query-tab'
              ]"
              to="/technographics/fin_query/query"
            >
              <div class="d-flex align-items-center">
                <div>
                  <span class="pr-2">
                    <i class="fa fa-university fi-bank-icon"></i>
                  </span>
                </div>
                <div class="" v-if="fiCount !== null">
                  <div v-if="fetchingFIs">
                    <i class="fa fa-spinner fa-spin"></i>
                  </div>
                  <div v-else>
                    <div v-if="isFIContactsFiltered" class="d-flex">
                      <h4 class="mb-0">
                        <b>{{ fiCount | numberFormat }}</b>
                      </h4>
                      <h6
                        class="mb-dot-1 text-muted font-italic pl-2 align-self-end"
                      >
                        (<i
                          class="fa fa-spinner fa-spin px-1"
                          v-if="loadingContactCount"
                        ></i
                        ><b v-else>{{ fisWithContactsCount }}</b> FIs with
                        Contacts)
                      </h6>
                    </div>
                    <h4 class="mb-0" v-else>
                      <b>{{ fiCount | numberFormat }}</b>
                    </h4>
                  </div>
                  <h6 class="mb-0">
                    Matching {{ fiCount | pluralize('Institution') }}
                  </h6>
                </div>
                <div v-else>
                  <div v-if="fetchingFIs">
                    <i class="fa fa-spinner fa-spin"></i>
                  </div>
                  <div v-else>
                    <h4 class="mb-0">
                      <b> - </b>
                    </h4>
                  </div>
                  <h6 class="mb-0">
                    Matching Institutions
                  </h6>
                </div>
              </div>
            </router-link>

            <router-link
              tag="div"
              class="col-sm-3 d-flex align-items-center p-2 segment-tab report-element-tab"
              to="/technographics/fin_query/report"
            >
              <div class="d-flex align-items-center">
                <div class="">
                  <span class="pr-2">
                    <i class="fa fa-th fi-report-element-icon"></i>
                  </span>
                </div>
                <div class="pl-1">
                  <div class="d-flex">
                    <div v-if="fetchingFIs">
                      <i class="fa fa-spinner fa-spin"></i>
                    </div>
                    <div class="d-flex" v-else>
                      <h4 class="mb-0">
                        <b>{{ dataElementsCount | numberFormat }}</b>
                      </h4>
                      <div>
                        <span class="badge badge-primary rounded py-1 px-2 ml-2"
                          >View Report</span
                        >
                      </div>
                    </div>
                  </div>
                  <h6 class="mb-0">
                    Data {{ dataElementsCount | pluralize('Element') }}
                  </h6>
                </div>
              </div>
            </router-link>

            <router-link
              tag="div"
              class="col-sm-4 std-border align-items-center p-2 segment-tab download-tab alert-info"
              to="/technographics/fin_query/download"
            >
              <div class="d-flex align-items-center">
                <div class="">
                  <span class="pr-2">
                    <i class="fa fi-report-download-icon fa-download fa-3x"></i>
                  </span>
                </div>
                <div class="pr-1 pl-2">
                  <div v-if="fetchingPriceData">
                    <i class="fa fa-spinner fa-spin"></i>
                  </div>
                  <div v-else>
                    <div class="d-flex">
                      <h4 class="mb-0" v-if="canShowPurchaseText">
                        <b>${{ totalPriceInDollars | numberFormat }}</b>
                        <small>
                          <sup>{{ totalPriceInCents }}</sup>
                        </small>
                      </h4>
                      <h4 class="mb-0" v-else>
                        <b v-if="canShowPurchaseText"> - </b>
                      </h4>
                      <div
                        :class="[
                          'd-flex align-self-center',
                          canShowPurchaseText ? 'pl-2' : ''
                        ]"
                      >
                        <span
                          :class="[
                            canShowPurchaseText ? ' p-1 ml-2 px-2' : 'p-1 pe-2',
                            'badge badge-primary rounded'
                          ]"
                          >Preview Report</span
                        >
                      </div>
                    </div>
                  </div>
                  <div>{{ purchaseText }}</div>
                </div>
              </div>
            </router-link>
            <div class="col-sm-3"></div>
          </div>
        </div>
      </div>

      <v-wait for="loadingInitData">
        <template slot="waiting">
          <content-placeholders :rounded="true" class="pt-4">
            <content-placeholders-text :lines="15"></content-placeholders-text>
          </content-placeholders>
        </template>
        <div>
          <keep-alive>
            <router-view ref="queryTabComponent"></router-view>
          </keep-alive>
        </div>
      </v-wait>
    </b-card>
  </div>
</template>

<script>
//global
import store from '@/store/store'
import router from '@/router/index'
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
//module utilities
import { elementCount } from '../helpers/utilities.js'
import Multiselect from 'vue-multiselect'
import FinQueryTopPanelForMortgageLenders from '../components/FinQueryTopPanelForMortgageLenders'

export default {
  name: 'AdvancedQuery',
  components: {
    Multiselect,
    FinQueryTopPanelForMortgageLenders
  },
  created() {
    if (!this.partnerSite) {
      document.title = 'FIN Query'
    }
    if (!this.isToolsPage) {
      this.resetReportWriterState()
      this.resetAdvancedQueryData()
      this.setBaseQueryAsCurrentQuery()
      this.loadInitData()
      this.resetReportData()
      this.setSelectedCompanyType()
    }
  },
  beforeRouteEnter(to, from, next) {
    if (store.getters['Ability/showFinQuery']) {
      next()
    } else {
      router.push('/fi_prospect/about_fin_query')
    }
  },
  beforeDestroy() {
    this.resetReportWriterState()
    this.resetAdvancedQueryData()
  },
  data() {
    return {
      companyTypes: ['Banks & Credit Unions', 'Mortgage Lenders (HMDA)'],
      selectedCompanyType: 'Banks & Credit Unions'
    }
  },
  computed: {
    ...mapState('AdvancedQuery', {
      fiCount: state => state.fiCount,
      fetchingFIs: state => state.fetchingFIs,
      queryElements: state => state.queryElements,
      fetchingPriceData: state => state.fetchingPriceData,
      fisWithContactsCount: state => state.fisWithContactsCount
    }),
    ...mapState({
      partnerSite: state => state.partnerSite
    }),
    ...mapGetters('AdvancedQuery', [
      'totalPriceInDollars',
      'totalPriceInCents',
      'isMortgageLender'
    ]),
    ...mapGetters('Ability', ['isFinQueryAvailable', 'isMbcAvailable']),
    ...mapGetters('ReportWriter', ['isFIContactsFiltered']),
    ...mapState('ReportWriter', {
      defaultOptions: state => state.defaultOptions,
      reportElements: state => state.reportElements,
      loadingContactCount: state => state.loadingContactCount
    }),
    headerText() {
      return this.canShowMbc ? 'FIN Query - ' : 'FIN Query'
    },
    dataElementsCount() {
      return elementCount()
    },
    purchaseText() {
      if (this.totalPriceInDollars === null) {
        return this.canShowPurchaseText ? 'Buy & Download Now' : 'Download Now'
      }
      return this.totalPriceInDollars > 0
        ? 'Buy & Download Now'
        : 'Download Now'
    },
    isToolsPage() {
      return this.$route.name === 'ConferenceImport'
    },
    isBulkDownload() {
      return this.$route.name === 'CRMData'
    },
    canShowMbc() {
      return (
        this.isFinQueryAvailable &&
        this.isMbcAvailable &&
        !this.isToolsPage &&
        !this.isBulkDownload
      )
    },
    validPurchaseAmount() {
      return this.totalPriceInDollars && this.totalPriceInDollars > 0
    },
    canShowPurchaseText() {
      return this.validPurchaseAmount || !this.isFinQueryAvailable
    }
  },
  methods: {
    ...mapActions('AdvancedQuery', [
      'fetchQueryRules',
      'fetchSavedQueries'
      //'loadEnterprisePermissions'
    ]),
    ...mapActions('ReportWriter', ['fetchElementsInfo']),
    ...mapMutations('ReportWriter', {
      resetReportWriterState: 'resetState',
      resetReportData: 'clearReportData'
    }),
    ...mapMutations('AdvancedQuery', {
      resetAdvancedQueryData: 'resetState',
      setBaseQueryAsCurrentQuery: 'setBaseQueryAsCurrentQuery',
      setIsMortgageLender: 'setIsMortgageLender'
    }),
    loadInitData() {
      this.$wait.start('loadingInitData')

      Promise.all([
        this.fetchQueryRules(this.isMortgageLender),
        this.fetchElementsInfo()
        //this.loadEnterprisePermissions()
      ]).then(() => {
        this.$wait.end('loadingInitData')
      })
    },
    setCompanyType() {
      this.resetReportWriterState()
      this.resetAdvancedQueryData()
      this.setBaseQueryAsCurrentQuery()
      this.resetReportData()
      this.setIsMortgageLender(
        this.selectedCompanyType === 'Mortgage Lenders (HMDA)' ? true : false
      )
      this.loadInitData()
    },
    setSelectedCompanyType() {
      this.selectedCompanyType = this.isMortgageLender
        ? 'Mortgage Lenders (HMDA)'
        : 'Banks & Credit Unions'
    }
  }
}
</script>

<style lang="scss" src="../scss/advanced.query.scss"></style>

<style lang="scss" scoped>
.segment-tab {
  cursor: pointer;
}

.fi-bank-icon {
  font-size: 2.9em;
}

.fi-report-element-icon {
  font-size: 3.1em;
  padding-top: 0.1em;
}

.fi-report-download-icon {
  font-size: 3em;
  padding-top: 0.1em;
}

.query-tab {
  &.active {
    background-color: #f0f3f5;
  }
}

.report-element-tab {
  border-top: 1px solid #c2cfd6;
  border-bottom: 1px solid #c2cfd6;

  &.active {
    background-color: #f0f3f5;
  }
}

.download-tab {
  &.active {
    border-color: #4a7389;
  }
}

.badge {
  color: white;
}
.download {
  color: black;
}
</style>
