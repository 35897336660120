<template>
  <v-wait for="fetchProfileDetails">
    <template slot="waiting">
      <content-placeholders :rounded="true">
        <content-placeholders-text :lines="7"></content-placeholders-text>
      </content-placeholders>
    </template>
    <div class="row">
      <div class="col-sm-8">
        <h5>
          <b
            >{{ profileDetail.name }}, {{ profileDetail.city }},
            {{ profileDetail.state }}</b
          >
        </h5>
      </div>
      <div :class="[!isMobile ? 'text-right' : '', 'col-sm-4']">
        <span
          class="site-link"
          v-if="profileDetail.website_url && profileDetail.website_url !== '0'"
        >
          <a target="_blank" :href="formatLink()">
            {{ profileDetail.website_url }}
            <sup>
              <i class="fa fa-external-link" aria-hidden="true"></i>
            </sup>
          </a>
        </span>
        <span
          class="cursor-pointer"
          title="linkedin"
          v-if="profileDetail.linkedin_url"
          @click="openLink(profileDetail.linkedin_url)"
        >
          <i
            class="fa fa-linkedin-square px-1 pl-2 fa-lg"
            aria-hidden="true"
          ></i>
        </span>
        <span
          class="cursor-pointer"
          title="facebook"
          v-if="profileDetail.facebook_url"
          @click="openLink(profileDetail.facebook_url)"
        >
          <i class="fa fa-facebook-official px-1 fa-lg" aria-hidden="true"></i>
        </span>
        <span
          class="cursor-pointer"
          title="twitter"
          v-if="profileDetail.twitter_url"
          @click="openLink(profileDetail.twitter_url)"
        >
          <i class="fa fa-twitter px-1 fa-lg" aria-hidden="true"></i>
        </span>
      </div>
    </div>

    <div class="row p-2">
      <div class="col-sm-12">
        <div class="row">
          <div class="col-sm-6">
            <div class="row mb-3">
              <div class="col-sm-12">
                <div class="row">
                  <div class="col-sm-12">
                    <h6 class="mb-1"><b>Institution Size</b></h6>
                  </div>
                </div>
                <div class="row">
                  <div class="col-5 ">Total Assets:</div>
                  <div class="col-7">
                    {{ profileDetail.assets }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-5">Branches:</div>
                  <div class="col-7">{{ profileDetail.branches }}</div>
                </div>
                <div class="row">
                  <div class="col-5">Employees:</div>
                  <div class="col-7">{{ profileDetail.employees }}</div>
                </div>
                <div class="row">
                  <div class="col-5">Federal Reserve RSSD ID:</div>
                  <div class="col-7 align-self-end">{{ profileDetail.id }}</div>
                </div>
                <div class="row">
                  <div class="col-5">Regulatory ID:</div>
                  <div class="col-7 align-self-end">
                    {{ profileDetail.fdic_certificate_number }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-5">ABA Number:</div>
                  <div class="col-7">
                    {{
                      fixedLengthPrimaryAbaRoutingNumber(
                        profileDetail.primary_aba_routing_number
                      )
                    }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-5">Deposit Accounts:</div>
                  <div class="col-7">
                    {{ profileDetail.deposit_accounts }}
                  </div>
                </div>
                <div class="row" v-if="isCU">
                  <div class="col-5">CU Members:</div>
                  <div class="col-7">
                    {{ profileDetail.total_cu_members || 0 }}
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-sm-12">
                <div class="row">
                  <div class="col-sm-12">
                    <h6 class="mb-1 d-inline-block"><b>Strategic Focus</b></h6>
                    <sup>
                      <i
                        class="icon-info icons font-weight-bold cursor-pointer"
                        @click="$refs[`info-strategy`].show()"
                      ></i>
                    </sup>
                  </div>
                  <b-modal
                    size="lg"
                    ref="info-strategy"
                    class="info-modal"
                    title="Strategic Focus"
                    ok-only
                  >
                    <div>
                      <span
                        v-html="moreInfo[selectedInstitution.fiType]"
                      ></span>
                    </div>
                  </b-modal>
                </div>
                <div class="row">
                  <div class="col-5">Lending:</div>
                  <div class="col-7">{{ profileDetail.lending }}</div>
                </div>
                <div class="row">
                  <div class="col-5">Funding:</div>
                  <div class="col-7">{{ profileDetail.funding }}</div>
                </div>
                <div class="row">
                  <div class="col-5">OBS Fee:</div>
                  <div class="col-7">{{ profileDetail.obs_fee }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="row mb-3">
              <div class="col-sm-12">
                <div class="row">
                  <div class="col-sm-12">
                    <h6 class="mb-1"><b>Structure & Charter</b></h6>
                  </div>
                </div>
                <div class="row" v-if="!isCU">
                  <div class="col-5">
                    {{
                      profileDetail.fi_type === 'bhc'
                        ? 'Subs:'
                        : 'Holding Company:'
                    }}
                  </div>
                  <div v-b-modal.sub-structure class="col-7 bhc-ss-link">
                    {{ profileDetail.bhc }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-5">Charter:</div>
                  <div class="col-7">{{ profileDetail.charter_class }}</div>
                </div>
                <div class="row">
                  <div class="col-5">Established:</div>
                  <div class="col-7">{{ profileDetail.established }}</div>
                </div>
                <div class="row" v-if="!isCU">
                  <div class="col-5">Sub-S:</div>
                  <div class="col-7">{{ profileDetail.subchapters }}</div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12">
                <div class="row">
                  <div class="col-sm-12">
                    <h6 class="mb-1"><b>Market Area</b></h6>
                  </div>
                </div>
                <div :class="[isMobile ? 'row pb-2' : 'row']">
                  <div class="col-5 pr-2">
                    Market Size<sup
                      ><i
                        class="icon-info icons font-weight-bold  cursor-pointer"
                        @click="$refs[`info-marketSize`].show()"
                      ></i
                    ></sup>
                    :
                  </div>
                  <div class="col-7">{{ profileDetail.market_size }}</div>
                  <b-modal
                    size="lg"
                    ref="info-marketSize"
                    class="info-modal"
                    title="Market Size"
                    ok-only
                  >
                    <div>
                      <p>
                        Market Size is segmented utilizing the NCHS Urban-Rural
                        Classification Scheme for Counties. NCHS has developed a
                        six-level urban-rural classification scheme for U.S.
                        counties and county- equivalent entities.The scheme has
                        six (6) market size classifications: Large Central
                        Metro, Large Fringe Metro, Medium Metro, Small Metro,
                        Micropolitan and Rural (non-core). To classify a
                        financial institutions footprint, the analysis
                        aggregates all of its county market and produce an
                        average for the counties. The NCHS classification is
                        quantitative based on county population. Metropolitan
                        counties are either: Large Central Metro counties in MSA
                        of 1 million population that: 1) contain the entire
                        population of the largest principal city of the MSA, or
                        2) are completely contained within the largest principal
                        city of the MSA, or 3) contain at least 250,000
                        residents of any principal city in the MSA. Large Fringe
                        Metro are counties in MSA of 1 million or more
                        population that do not qualify as Large Central Metro.
                        Medium Metro are counties in MSA of 250,000-999,999
                        population. Small Metro counties are counties in MSAs of
                        less than 250,000 population. Non-Metropolitan counties:
                        Micropolitan counties in micropolitan statistical area;
                        Rural (Non-Core) counties are not in micropolitan
                        statistical areas
                      </p>
                    </div>
                  </b-modal>
                </div>
                <div :class="[isMobile ? 'row pb-2' : 'row']">
                  <div class="col-5">
                    Market Coverage<sup
                      ><i
                        class="icon-info icons font-weight-bold  cursor-pointer"
                        @click="$refs[`info-marketCoverage`].show()"
                      ></i
                    ></sup>
                    :
                  </div>
                  <div class="col-7">
                    {{ profileDetail.market_coverage }}
                  </div>
                  <b-modal
                    size="lg"
                    ref="info-marketCoverage"
                    class="info-modal"
                    title="Market Coverage"
                    ok-only
                  >
                    <div>
                      <p>
                        The Market Coverage segmentation classifies the
                        footprint type of the financial institution into one of
                        seven (7) segments: (1) National (2) Regional (3)
                        Interstate (4) Intrastate (5) Multi- Community (6)
                        Community or (7) Internet-Only. For the segments with
                        physical geographic locations, we'll detail the
                        classification segments working from smallest to
                        largest. Community institutions have locations only
                        within one county market. Multi-Community footprints
                        have locations in more than one (1) and less than six
                        (6) counties within a single state. Intrastate
                        footprints have locations in six (6) or more counties
                        from within a single state. Interstate coverage includes
                        institutions with locations in two (2) or more and less
                        than eight (8) states. Regional footprints occupy more
                        than eight (8) states but less than three (3) U.S.
                        regions. National footprints cover three or more U.S.
                        regions and twenty-five (25) or more states .
                        Internet-only institutions only serve customers
                        digitally without any physical locations as a direct
                        bank or standalone internet bank (SAIB).
                      </p>
                    </div>
                  </b-modal>
                </div>
                <div :class="[isMobile ? 'row pb-2' : 'row']" v-if="isCU">
                  <div class="col-5">Type of Membership</div>
                  <div class="col-7">
                    {{ profileDetail.type_of_membership }}
                  </div>
                </div>
                <div :class="[isMobile ? 'row pb-2' : 'row']" v-if="isCU">
                  <div class="col-5">Low Income Designation</div>
                  <div class="col-7">
                    {{ profileDetail.low_income_designation }}
                  </div>
                </div>
                <div :class="[isMobile ? 'row pb-2' : 'row']">
                  <div class="col-5">Main Address:</div>
                  <div class="col-7">
                    {{ profileDetail.address }}
                  </div>
                </div>
                <div :class="[isMobile ? 'row pb-2' : 'row']">
                  <div class="col-5">City, State, ZIP:</div>
                  <div class="col-7">
                    {{ profileDetail.city || '-' }},
                    {{ profileDetail.state || '-' }},
                    {{ profileDetail.postal_code || '-' }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-wait>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { formatUrl } from '../../../utilities'

export default {
  name: 'BankProfile',
  data() {
    return {
      sectionName: 'Bank Profile',
      moreInfo: {
        bank: `<p>FI Navigator has developed a strategic classification system to ensure the matching of a strategic peer group that shares a similar lending, funding and off-balance sheet focus. A financial institution’s operating strategy, as reflected by its balance sheet and revenue composition heavily influences its route towards high performance. Comparison to strategic comparables generates performance exceptions (or conclusions) that, when addressed, are much more likely to impact performance.</p>
<p>The base strategic classification divides financial institutions into five (5) lending strategies, three(3) funding strategies and three (3) off-balance sheet revenue levels. These combinations result in 45 strategic classes. An additional two (2) classifications cover the atypical strategies of credit card institutions and bankers’ banks.</p>
<img src='https://apps.finavigator.com/img/bank-strategy.d69d5111.png' style =" max-width:100%; height: auto;">`,
        credit_union: `<p>FI Navigator has developed a strategic classification system to ensure the matching of a strategic peer group that shares a similar lending, funding and off- balance sheet focus. A credit union’s operating strategy, as reflected by its balance sheet and revenue composition heavily influences its route towards higher performance. Comparison to strategic comparables generates performance exceptions (or conclusions) that, when addressed, are much more likely to accurately guide resource allocation for performance improvement.</p>
<p>The base strategic classification divides financial institutions into four (4) lending strategies, three (3) funding strategies and two (2) off-balance sheet revenue levels. These combinations result in 24 strategic classes.</p>
<p>The four lending strategies (and their abbreviated labels) are secured personal lending (AUTO), residential real estate lending (HOME), unsecured personal lending (UPER) and diverse lending focus (DIVR). The AUTO loan focus group is for institutions where secured personal lending exceeds the 60th percentile and exceeds both residential real estate and unsecured personal lending for the institution. The HOME loan focus group is for institutions where residential real estate lending exceeds the 75th percentile and exceeds both secured personal and unsecured personal lending for the institution. The UPER loan focus group is for institutions where unsecured lending exceeds the 66th percentile and exceeds both secured personal and residential real estate lending for the institution. The DIVR loan focus group is for credit unions with more diverse portfolios that would not meet the thresholds for the other three groups.</p>
<p>The three funding strategies (and their abbreviated labels) are low share balance (LBAL), moderate share balance (MBAL) and high share balance (HBAL). The classifications utilize the institutions average share balance with those under the 33rd percentile in the low balance (LBAL) group and those between the 33rd and 60th percentile in the moderate balance (MBAL) group. The high balance (HBAL) group’s average balances exceeds the 60th percentile. Lower share balances are highly correlated with lower cost of deposits and higher fee income levels – but are also associated with higher personnel and other expenses.</p>
<p>The two off-balance sheet classifications are moderate (MOD) and high (HGH). The classification examines the credit union’s level of other operating income relative to average earning assets. Other operating income for the institution includes real estate loan secondary market income, interchange income, and other recurring non-service charge income. As such the metric is a proxy for expanded and/or off-balance sheet income sources. Institutions below the 40th percentile in other operating income to average earning assets are designated as moderate (MOD) with the remainder designated as high (HGH).</p>
<img src='https://apps.finavigator.com/img/cu-strategy.090ffb39.png' style =" max-width:100%; height: auto;">
`,
        bhc: `<p>FI Navigator has developed a strategic classification system to ensure the matching of a strategic peer group that shares a similar lending, funding and off-balance sheet focus. A financial institution’s operating strategy, as reflected by its balance sheet and revenue composition heavily influences its route towards high performance. Comparison to strategic comparables generates performance exceptions (or conclusions) that, when addressed, are much more likely to impact performance.</p>
<p>The base strategic classification divides financial institutions into five (5) lending strategies, three(3) funding strategies and three (3) off-balance sheet revenue levels. These combinations result in 45 strategic classes. An additional two (2) classifications cover the atypical strategies of credit card institutions and bankers’ banks.</p>
<img src='https://apps.finavigator.com/img/bank-strategy.d69d5111.png' style =" max-width:100%; height: auto;">`
      }
    }
  },
  props: {
    sectionInfo: {
      type: Object,
      required: true,
      default() {
        return {}
      }
    },
    renderedForPDF: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  created() {},
  computed: {
    ...mapState('Profile', {
      selectedInstitution: state => state.selectedInstitution,
      profileDetail: state => state.profileDetail,
      isMobile: state => state.isMobile
    }),
    isCU() {
      return this.profileDetail.fi_type === 'credit_union'
    }
  },
  methods: {
    ...mapActions('Profile', ['fetchBankProfileDetails']),
    fixedLengthPrimaryAbaRoutingNumber(abaNum) {
      if (abaNum && abaNum !== 0 && abaNum.length !== 9) {
        return String(abaNum).padStart(9, '0')
      }
      return abaNum
    },
    fetchProfileDetails() {
      this.$wait.start('fetchProfileDetails')
      return this.fetchBankProfileDetails({
        fiId: this.selectedInstitution.id
      }).then(() => {
        this.$wait.end('fetchProfileDetails')
      })
    },
    configChanged() {
      this.fetchProfileDetails()
    },
    formatLink() {
      return formatUrl(this.profileDetail.website_url)
    },
    openLink(url) {
      window.open(formatUrl(url), '_blank')
    }
  },
  watch: {}
}
</script>

<style lang="scss" scoped>
.site-link {
  font-size: 1rem;
}
.bhc-ss-link {
  cursor: pointer;
  color: #20a8d8;
}
</style>
