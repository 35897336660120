import Vue from 'vue'
import axios from 'axios'
import router from '@/router'
import ahoy from 'ahoy.js'

export const logIn = function({ dispatch, commit }, payload) {
  let data = new window.FormData()
  let pageUrl = this.getters.redirectUrl

  data.append('session[email]', payload.user.email)
  data.append('session[password]', payload.user.password)

  return axios
    .post('/api/sessions', data, {
      handleErrors: false
    })
    .then(res => {
      commit('setUserData', res.data)
      commit('setFinancialInstitutionBoolean', null, { root: true })
      commit('Ability/setUserRole', res.data.role.name, { root: true })
      commit('Ability/setUserAbility', null, { root: true })
      commit('Ability/setModulePermissions', res.data.module_permission, {
        root: true
      })
      commit('Ability/setEnabledModules', res.data.ui_modules, { root: true })
      commit('Ability/setEnabledPaths', res.data.ui_modules, { root: true })
      commit(
        'Ability/setEnterprisePermissions',
        res.data.enterprise_permissions,
        { root: true }
      )
      commit(
        'Ability/setActiveClientPricingAvailable',
        res.data.active_client_pricing,
        { root: true }
      )
      dispatch('ThirdPartyServices/setUserDataToChameleon', null, {
        root: true
      })

      if (!payload.skipRedirect) {
        if (pageUrl) {
          commit('setRedirectUrl', null, { root: true })
          router.push(pageUrl)
        } else {
          router.push('/')
        }
      }

      Vue.toasted.show('Logged in as ' + res.data.user_name, {
        icon: 'user-circle',
        type: 'success'
      })

      if (process.env.NODE_ENV !== 'development') {
        Vue.$honeybadger.setContext({
          user: res.data.email
        })
      }

      ahoy.track('login', { user_id: res.data.id })
    })
    .catch(error => {
      if (error.response && error.response.status === 401) {
        Vue.toasted.show(error.response.data.message, {
          icon: 'user-times',
          type: 'error'
        })
      } else if (error.response && error.response.status === 423) {
        commit('setIsUserActive', false)
        commit('setUserInactiveEmail', payload.user.email)

        Vue.nextTick(function() {
          router.push('/verify_email')
        })

        Vue.toasted.show(
          'To continue using, please enter the verification code sent to registered email.',
          {
            icon: 'user-circle',
            type: 'success'
          }
        )
      } else {
        Vue.toasted.show(
          'Sorry, an error has occurred with login. Please try again. If the problem continues, please know that our team has been alerted.',
          {
            icon: 'chain-broken',
            type: 'error'
          }
        )
        if (!payload.skipRedirect) {
          router.push('/sign_in')
        }
      }
    })
}

export const verifyOTP = function({ commit }, payload) {
  axios
    .get('/api/users/verify_otp', {
      params: { email: payload.email, otp: payload.otp },
      handleErrors: false
    })
    .then(
      () => {
        commit('setIsUserActive', true)

        Vue.toasted.show(
          'Email successfully verified. Please relogin to continue.',
          {
            icon: 'user-circle',
            type: 'success'
          }
        )

        router.push({ name: 'SignIn', params: { silent: true } })
      },
      () => {
        Vue.toasted.show('Invalid token. please retry.', {
          icon: 'chain-broken',
          type: 'error'
        })
      }
    )
}

export const resendOTP = function({ commit }, payload) {
  commit('setIsUserActive', false)

  return axios
    .get('/api/users/resend_otp', {
      params: { email: payload.email },
      handleErrors: true
    })
    .then(
      () => {
        Vue.toasted.show('Verification code sent to email.', {
          icon: 'user-circle',
          type: 'success'
        })
      },
      () => {
        Vue.toasted.show('Something went wrong. Please retry.', {
          icon: 'user-circle',
          type: 'success'
        })
      }
    )
}

export const logOut = function({ commit }, payload = {}) {
  axios
    .delete('sign_out', {
      handleErrors: true
    })
    .then(() => {
      commit('resetAuthData')
      commit('clearStoredInstitutions', null, { root: true })
      commit('resetReporter', null, { root: true })
      commit('Advisor/resetData', null, { root: true })
      commit('Profile/resetProfileState', null, { root: true })
      commit('Ability/resetData', null, { root: true })
      commit('CustomPeerGroup/resetCustomPeerGroupDetails', null, {
        root: true
      })
      commit('setRedirectUrl', null, { root: true })
      commit('setPartnerSite', null, { root: true })
      commit('ReportWriter/resetState', null, { root: true })
      commit('IndustryReporter/resetIndustryReporterState', null, {
        root: true
      })
      commit('VendorFit/resetVendorFitState', null, { root: true })
      commit('OnlineEdits/resetOnlineEditsState', null, { root: true })
      commit('DigitalChannels/resetDigitalChannelsState', null, { root: true })
      commit('SuperAdmin/resetSuperAdminState', null, { root: true })
      commit('AdvancedQuery/resetQueryData', null, { root: true })
      commit('AdvancedQuery/resetFICountAndPriceData', null, { root: true })
      if (!payload.silent) {
        Vue.toasted.show('Logged out successfully', {
          icon: 'hand-stop-o',
          type: 'success'
        })
      }

      if (process.env.NODE_ENV !== 'development') {
        Vue.$honeybadger.resetContext()
      }

      if (!payload.skipRedirect) {
        router.push('/sign_in')
      }
    })
    .catch(error => {
      if (error) {
        Vue.toasted.show(
          'Sorry, an error has occurred with logout. Please try again. If the problem continues, please know that our team has been alerted.',
          {
            icon: 'chain-broken',
            type: 'error'
          }
        )
        router.push('/sign_in')
      }
    })
}

export const resetStoreData = function({ commit }) {
  commit('resetAuthData')
  commit('clearStoredInstitutions', null, { root: true })
  commit('resetReporter', null, { root: true })
  commit('Advisor/resetData', null, { root: true })
  commit('Profile/resetData', null, { root: true })
  commit('Ability/resetData', null, { root: true })
  commit('CustomPeerGroup/resetCustomPeerGroupDetails', null, { root: true })
  commit('setRedirectUrl', null, { root: true })
  commit('setPartnerSite', null, { root: true })
  commit('ReportWriter/resetState', null, { root: true })
  commit('IndustryReporter/resetIndustryReporterState', null, {
    root: true
  })
  commit('VendorFit/resetVendorFitState', null, { root: true })
  commit('OnlineEdits/resetOnlineEditsState', null, { root: true })
  commit('DigitalChannels/resetDigitalChannelsState', null, { root: true })
  commit('SuperAdmin/resetSuperAdminState', null, { root: true })
  commit('AdvancedQuery/resetQueryData', null, { root: true })
  commit('AdvancedQuery/resetFICountAndPriceData', null, { root: true })
}

export const fetchUserGroups = function({ commit }) {
  return axios
    .get('/api/users/groups', {
      handleErrors: true
    })
    .then(res => {
      commit('setUserGroups', res.data.groups)
    })
}
