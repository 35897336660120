import { render, staticRenderFns } from "./Ethos.vue?vue&type=template&id=4bfd4896&"
import script from "./Ethos.vue?vue&type=script&charset=utf-8&lang=js&"
export * from "./Ethos.vue?vue&type=script&charset=utf-8&lang=js&"
import style0 from "../cu2/_ethos_theme.scss?vue&type=style&index=0&lang=scss&"
import style1 from "./Ethos.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports