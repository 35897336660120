<template>
  <PageCard pageName="Mock Users" :iconClass="['fa', 'fa-users', 'fa-lg']">
    <template slot="page_content">
      <div v-if="$apollo.loading">
        <content-placeholders :rounded="true">
          <content-placeholders-text :lines="20"></content-placeholders-text>
        </content-placeholders>
      </div>
      <div v-else>
        <div class="row justify-content-center">
          <div class="col-sm-8 mb-3"></div>
          <div class="col-sm-4 text-right mb-3 mt-2">
            <b-input-group>
              <div class="input-group-prepend">
                <span class="input-group-text"
                  ><i class="fa fa-search"></i
                ></span>
              </div>
              <b-form-input
                type="text"
                v-model="filterText"
                @keydown.native.stop
                placeholder="Search"
              ></b-form-input>
            </b-input-group>
          </div>
          <div class="col-sm-12">
            <b-table
              :items="listingUsers"
              :fields="tableHeaders"
              :filter="filterText"
              :filter-included-fields="filterOn"
              @filtered="afterFilter"
              :show-empty="true"
              :current-page="currentPage"
              :per-page="perPage"
              empty-text="No records found."
              responsive="md"
              striped
              hover
              small
            >
              <template v-slot:cell(mockUser)="data">
                <b-button
                  size="sm"
                  variant="primary"
                  @click="setSelectedUser(data.item)"
                  v-b-modal.mockConfirmation
                  >Mock</b-button
                >
              </template>
            </b-table>
            <b-row>
              <b-col md="6">
                <b-pagination
                  :total-rows="filteredUsers ? filteredUsers.length : totalRows"
                  :per-page="perPage"
                  v-model="currentPage"
                >
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
      <b-modal
        id="mockConfirmation"
        ref="mockConfirmation"
        title="Mock User"
        @ok="mockUser"
        @cancel="resetSelectedUser"
      >
        <p>Are you sure, want to mock the user?</p>
      </b-modal>
    </template>
  </PageCard>
</template>

<script>
// global
import Vue from 'vue'
import store from '@/store/store'
import router from '@/router/index'
import { mapActions, mapMutations } from 'vuex'
// ui components
import PageCard from '@/modules/core/components/layouts/PageCard'
import gql from 'graphql-tag'
// api
import userAPI from '@/api/finapps/super_admin/users'

export default {
  name: 'MockUsers',
  components: {
    PageCard
  },
  beforeCreate() {
    let hasPermission = store.getters['Ability/manageSuperAdminBoard']

    if (!hasPermission) {
      Vue.toasted.show('Page access restricted. Please contact admin.', {
        icon: 'chain-broken',
        type: 'error'
      })
      router.push('/dashboard')
    }
  },
  created() {},
  data() {
    return {
      users: [],
      currentPage: 1,
      perPage: 20,
      selectedUser: null,
      roles: [],
      groups: [],
      allUsers: [],
      filterOn: ['firstName', 'email', 'company', 'phone', 'role_name'],
      filterText: null,
      filteredUsers: null
    }
  },
  computed: {
    listingUsers() {
      return this.allUsers
    },
    totalRows() {
      return this.listingUsers.length
    },
    tableHeaders() {
      return [
        { key: 'firstName', label: 'Name', sortable: true },
        { key: 'email', label: 'Email', sortable: true },
        { key: 'company', label: 'Company', sortable: true },
        { key: 'phone', label: 'Phone', sortable: true },
        { key: 'role_name', label: 'Role', sortable: true },
        { key: 'mockUser', label: '', sortable: false }
      ]
    }
  },
  apollo: {
    allUsers: {
      query: gql`
        {
          allUsers(orderBy: "email") {
            id
            firstName
            email
            company
            phone
            isApproved
            role {
              name
            }
          }
        }
      `,
      client: 'apolloClient',
      update(data) {
        let usersData = data.allUsers.filter(user => user.isApproved != false)
        usersData.forEach(user => {
          user.role_name = user.role ? user.role.name : null
        })
        return usersData
      }
    }
  },
  methods: {
    ...mapActions('ThirdPartyServices', ['setUserDataToChameleon']),
    ...mapMutations(['setFinancialInstitutionBoolean']),
    ...mapMutations('Ability', [
      'setUserRole',
      'setUserAbility',
      'setEnabledModules',
      'setEnabledPaths',
      'setEnterprisePermissions',
      'setActiveClientPricingAvailable',
      'setModulePermissions'
    ]),
    ...mapMutations('Authentication', ['setUserData', 'setMockUser']),
    afterFilter: function(filteredItems) {
      this.filteredUsers = filteredItems
      this.currentPage = 1
    },
    setSelectedUser(user) {
      this.selectedUser = user
    },
    resetSelectedUser() {
      this.selectedUser = null
    },
    mockUser() {
      userAPI.mockUser(this.selectedUser.id).then(
        res => {
          this.setUserData(res)
          this.setFinancialInstitutionBoolean()
          this.setUserRole(res.role.name)
          this.setUserAbility()
          this.setModulePermissions(res.module_permission)
          this.setEnabledModules(res.ui_modules)
          this.setEnabledPaths(res.ui_modules)
          this.setActiveClientPricingAvailable(res.active_client_pricing)
          this.setEnterprisePermissions(res.enterprise_permissions)
          this.setUserDataToChameleon()
          this.setMockUser(true)
          this.$router.push('/')
        },
        error => {
          this.$toasted.global.invalid(error.msg)
        }
      )
    }
  }
}
</script>

<style lang="scss" scoped></style>
