import { render, staticRenderFns } from "./UseCaseDetails.vue?vue&type=template&id=6aded38d&scoped=true&"
import script from "./UseCaseDetails.vue?vue&type=script&lang=js&"
export * from "./UseCaseDetails.vue?vue&type=script&lang=js&"
import style0 from "../scss/infoCards.scss?vue&type=style&index=0&lang=scss&"
import style1 from "./UseCaseDetails.vue?vue&type=style&index=1&id=6aded38d&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6aded38d",
  null
  
)

export default component.exports