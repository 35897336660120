<template>
  <div class="px-4">
    <b-card class="page-card digital-channels-report" header-class="dc-header">
      <div slot="header">
        <b-row>
          <b-col cols="12">
            <div class="page-header-left header-align">
              <div class="d-flex align-items-center">
                <div class="pr-1 pb-2 header-icon">
                  <i class="fa fa-lg icon-screen-smartphone"></i>
                </div>
                <div class="pr-1 pb-2">
                  <h5 class="mb-0"><b>Digital Channels Report -</b></h5>
                </div>
                <div class="report-title">
                  <multiselect
                    :allowEmpty="false"
                    :showLabels="false"
                    :options="reportModules"
                    :multiple="false"
                    :close-on-select="true"
                    :searchable="false"
                    class="min-width-20 cursor-pointer dc-report-select"
                    v-model="selectedReportModule"
                  >
                  </multiselect>
                </div>
              </div>
              <div
                class="text-right profile-icon-tray d-flex align-items-flex-start"
              >
                <i
                  @click="printDCReport"
                  title="Print Report"
                  class="cuis-print card-icon-font px-1 xlsx-link cursor-pointer"
                ></i>
                <v-wait for="downloadingReport">
                  <template slot="waiting">
                    <i class="fa fa-lg px-2 fa-spinner fa-spin"></i>
                  </template>
                  <i
                    @click="downloadDCReport"
                    class="cuis-file-excel card-icon-font pr-2 cursor-pointer xlsx-link"
                    title="Download xlsx"
                  ></i>
                </v-wait>
                <div>
                  <i
                    class="fa fa-cog fa-lg cursor-pointer xlsx-link reset-vertical-align"
                    :disabled="isAsideOpen"
                    @click="showSettings"
                  ></i>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
      <v-wait for="loadingReportBasicData">
        <template slot="waiting">
          <content-placeholders :rounded="true">
            <content-placeholders-text :lines="15"></content-placeholders-text>
          </content-placeholders>
        </template>

        <div class="dc-pdf-header">
          <div class="pb-3">
            <div class="row">
              <div class="col-sm-12">
                <img src="/logo.png" class="report-logo" />
              </div>
            </div>
            <div class="row">
              <div class="pl-3 pt-2">
                <h3>
                  <b>Digital Channels Report - {{ selectedReportModule }}</b>
                </h3>
              </div>
            </div>
          </div>
        </div>
        <router-view></router-view>
      </v-wait>
    </b-card>
    <b-modal
      id="reportNotPrintableModal"
      @ok="show = false"
      title="Alert"
      ok-only
    >
      <p>
        Please select a shorter date range for print:<br />
        <b>Yearly:</b> &#60;= 8 years <br />
        <b>Quarterly:</b> &#60;= 8 quarters <br />
        <b>Monthly:</b> &#60;= 8 months
      </p>
    </b-modal>
  </div>
</template>

<script>
// global
// import Vue from 'vue'
import store from '@/store/store'
import router from '@/router/index'
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
// api
import digitalChannelsAPI from '@/api/finapps/digital.channels'
// ui components
import Multiselect from 'vue-multiselect'
import { mapFields } from 'vuex-map-fields'
// utilities
import _ from 'lodash'
export default {
  name: 'DigitalChannelsLayout',

  beforeRouteEnter(to, from, next) {
    if (store.getters['Ability/showDigitalChannels']) {
      document.title = 'FIN Digital Channels'
      next()
    } else {
      // Vue.toasted.show('Page access restricted. Please contact admin.', {
      //   icon: 'chain-broken',
      //   type: 'error'
      // })
      router.push('/digitalchannels/dashboard')
    }
  },
  beforeCreate() {
    // this.$store.dispatch('setAside', true)
  },
  beforeDestroy() {
    document.body.classList.add('aside-menu-hidden')
    this.$store.dispatch('setAsideToogle', false)
  },
  mounted() {
    //this.trackReportView()
    this.loadInitData()
  },
  data() {
    return {}
  },
  components: { Multiselect },
  computed: {
    //...mapGetters('Ability', ['manageSuperAdminBoard']),
    ...mapGetters(['isAsideOpen']),
    ...mapState('DigitalChannels', [
      'loadingReport',
      'reportTypes',
      'selectedReport',
      'downloadDCReportData',
      'selectedReportModule',
      'reportIsPrintable'
    ]),
    ...mapGetters('DigitalChannels', ['selectedFiltersMessage']),
    ...mapFields('DigitalChannels', ['selectedReportModule']),
    //...mapGetters('AdvancedQuery', ['isDigitalChannelsAvailable']),

    reportModules() {
      let groupedReports = _.groupBy(this.reportTypes, rt => rt.report_module)
      return Object.keys(groupedReports)
    }
  },
  methods: {
    ...mapMutations('DigitalChannels', [
      'setReportTypes',
      'setSelectedReport',
      'setReportFilters',
      'setSelectedReportModule'
    ]),
    ...mapActions('DigitalChannels', ['downloadReport']),
    // ...mapActions('AdvancedQuery', ['loadEnterprisePermissions']),
    showSettings(e) {
      e.preventDefault()
      document.body.classList.remove('aside-menu-hidden')
      this.$store.dispatch('setAsideToogle', true)
    },
    loadInitData() {
      this.$wait.start('loadingReportBasicData')

      Promise.all([this.loadReportsTypes(), this.loadReportFilters()]).then(
        () => {
          document.body.classList.remove('aside-menu-hidden')
          this.$store.dispatch('setAsideToogle', true)

          this.$wait.end('loadingReportBasicData')
        }
      )
    },
    loadReportsTypes() {
      if (this.reportTypes.length) {
        if (!this.selectedReport) {
          this.setSelectedReport(this.reportTypes[0])
        }

        return Promise.resolve()
      }

      this.$wait.start('loadingReportTypes')

      return digitalChannelsAPI.reports().then(res => {
        this.setReportTypes(res)

        if (!this.selectedReport) {
          this.setSelectedReport(this.reportTypes[0])
        }

        this.$wait.end('loadingReportTypes')
      })
    },
    loadReportFilters() {
      return digitalChannelsAPI.reportFilters().then(res => {
        this.setReportFilters(res)
      })
    },
    printDCReport() {
      setTimeout(
        function() {
          this.$nextTick(() => {
            this.$toasted.clear()
            if (this.isTrendReport() && !this.reportIsPrintable) {
              this.$bvModal.show('reportNotPrintableModal')
            } else {
              window.print()
            }
            return
          })
        }.bind(this),
        250
      )
    },
    isTrendReport() {
      return (
        this.selectedReport.report_type === 'Institution Analytics' &&
        [
          'Enrollment Trend',
          'Enrollment Trend Type',
          'Enrollment Growth',
          'Satisfaction Trend',
          'Satisfaction Trend Type',
          'Adoption Trend',
          'Adoption Trend Type'
        ].includes(this.selectedReport.name)
      )
    },
    downloadDCReport() {
      this.downloadDCReportData.filterText = this.selectedFiltersMessage
      this.downloadReport()
    }
  }
}
</script>

<style lang="scss" src="../scss/digital.channels.scss"></style>

<style lang="scss" scoped>
.header-align {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.reset-vertical-align {
  vertical-align: 0;
}

.dc-pdf-header {
  display: none;
}
</style>

<style lang="scss">
@media print {
  @page {
    size: portrait;
  }
  .digital-channels-report .market-report-container {
    .small-font-for-print {
      font-size: 0.5rem;
    }
    .less-padding-for-print {
      td {
        padding: 0.75rem 0.2rem;
      }
    }
    .landscape-trend-table {
      table td {
        padding: 0.75rem 0.25rem;
      }
      table {
        overflow: hidden;
      }
      .trend-col-1 {
        min-width: 0;
      }
      .trend-col-others {
        min-width: 0;
      }
      // .sort-icon {
      //   display: none;
      // }
    }
  }

  .digital-channels-report {
    border: solid 3px #e4e5e6;
    .dc-pdf-header {
      display: block;
    }

    .card-header.dc-header {
      display: none;
    }

    table {
      thead {
        display: table-row-group !important;
      }

      tr {
        page-break-before: avoid;
        page-break-after: avoid;
        page-break-inside: unset;
      }
    }
  }
}
</style>
