<template>
  <PageCard
    pageName="FIN Profile"
    :iconClass="['fa', 'icon-screen-smartphone', 'fa-lg']"
    :showHeader="false"
  >
    <template slot="page_content">
      <div class="homepage-content">
        <section class="  solution-section">
          <div class="">
            <div class="section container-margin" id="1">
              <FeatureDetailsCard
                title="FIN Technographics"
                description1="FI Navigator provides the most precise ABM available in any vertical combining in-depth performance, technographic, firmographic, market area, intent and segmentation data."
                description2="The FIN Query solution easily (1) filters prospect institutions (2) provides additional institution or contact data and (3) instantly exports the data for laser-focused campaigns."
                button1Text="See Use Cases"
                :button1Link="usecaseLink"
                :galleryImages="FI_SOL_FIN_Q"
              />
            </div>
          </div>
        </section>
      </div>
    </template>
  </PageCard>
</template>

<script>
// global
import { mapState, mapGetters } from 'vuex'
// layout
import PageCard from '@/modules/core/components/layouts/PageCard'
import FeatureDetailsCard from '../components/layouts/FeatureDetailsCard'

export default {
  name: 'AboutAdvisor',
  components: {
    PageCard,
    FeatureDetailsCard
  },
  data() {
    return {
      FI_SOL_FIN_Q: [
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_SOL_FIN_Q1.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_SOL_FIN_Q2.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_SOL_FIN_Q3.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_SOL_FIN_Q4.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_SOL_FIN_Q5.png'
      ]
    }
  },
  created() {
    if (!this.partnerSite) {
      document.title = 'FIN Technographics'
    }
  },
  computed: {
    ...mapState({
      partnerSite: state => state.partnerSite
    }),
    ...mapGetters('Authentication', ['isFiMenu']),
    usecaseLink() {
      return this.isFiMenu
        ? '/institution_analytics/about'
        : '/technographics/about'
    }
  }
}
</script>
<style lang="scss" src="../scss/infoCards.scss"></style>
<style lang="scss" scoped>
.homepage-content {
  background-size: cover;
}
</style>
