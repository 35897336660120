<template>
  <PageCard
    :pageName="
      partnerSite
        ? 'The Ethos Performance Intelligence'
        : 'Institution Analytics'
    "
    :iconClass="partnerSite ? [] : ['cuis-chart-line', 'card-icon-font']"
    :showHeader="false"
  >
    <template slot="page_content">
      <div v-if="partnerSite">
        <div class="row">
          <div class="col-sm-12">
            <p class="px-1">
              Launch the tools below to generate instant insights and drive the
              performance of your institution. Leverage data-driven consultative
              assessments and industry-leading peer analytics to outperform your
              competition.
            </p>
          </div>
        </div>

        <b-row class="about-row">
          <b-col cols="4" class="px-3">
            <b-card
              class="std-border mb-0"
              header-bg-variant="success"
              header-text-variant="white"
              footer-class="d-none"
            >
              <b-card-body class="p-0">
                <div>
                  <span><b>FIN Advisor</b></span>
                  : Instantly generate insights with an action plan to expertly
                  manage your institution.
                </div>

                <router-link
                  tag="button"
                  :to="{ path: '/analytics/advisor/select_institution' }"
                  class="btn btn-secondary fw-btn my-3 p-1 br-1-em"
                  >Launch FIN Advisor</router-link
                >
                <b-card-img
                  src="../../../../static/img/ethos/advisor.png"
                  class="rounded-0 my-2"
                />
              </b-card-body>
            </b-card>
          </b-col>
          <b-col cols="4" class="px-1">
            <b-card
              class="std-border mb-0"
              header-bg-variant="success"
              header-text-variant="white"
            >
              <b-card-body class="p-0">
                <div>
                  <span><b>FIN Reporter</b></span>
                  : Instantly generate presentations and custom reports for any
                  meeting scenario.
                </div>

                <router-link
                  tag="button"
                  :to="{ path: '/analytics/reporter/select_institution' }"
                  class="btn btn-secondary fw-btn my-3 p-1 br-1-em"
                  >Launch FIN Reporter</router-link
                >
                <b-card-img
                  src="../../../../static/img/ethos/reporter.png"
                  class="rounded-0 my-2"
                />
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </div>

      <div class="homepage-content" v-else>
        <section class="  solution-section">
          <div class="">
            <div class="section container-margin" id="1">
              <div class="ps-2">
                <div class="row card-row">
                  <div class="col-9">
                    <h1 class="card-title d-inline-block pr-3 banner-title">
                      Institution Analytics
                    </h1>
                    <div class="solution-icon same-col soln-img"></div>
                  </div>
                </div>
                <p class="card-text mb-4 soln-desc">
                  Peer Data &amp; Analytics to Pinpoint Your Best Opportunities
                </p>
              </div>
              <FeatureDetailsCard
                title="FIN Query"
                description1="Deploy FIN Query to leverage the powerful combination
                          of firmographics, technographics, performance, client
                          offering &amp; contact data for FI peer grouping and
                          research."
                description2="In three easy steps, FIN Query (1) filters prospect
                          institutions, (2) provides additional institution or
                          contact data, and (3) instantly exports the data."
                button1Text="See Use Cases"
                button1Link="#usecases"
                :galleryImages="FI_SOL_FIN_Q"
              />
              <FeatureDetailsCard
                title="FIN Advisor"
                description1="Deploy FIN Advisor to instantly analyze any
                          institution’s strategy, performance and offering."
                description2="Easily generate a consultative assessment of profit,
                          growth, risk, offering &amp; technologies with primary
                          opportunity themes, strengths/weaknesses &amp; custom
                          recommendations. Position your team to quickly
                          formulate strategy and prioritize resource allocation."
                button1Text="See Use Cases"
                button1Link="#usecases"
                :galleryImages="FI_SOL_FIN_A"
              />
              <FeatureDetailsCard
                title="FIN Reporter"
                description1="Utilize FIN Reporter to create customized reports
                          leveraging unrivaled FI performance, offering &amp;
                          technologies data."
                description2="FIN Reporter delivers complete control to users on
                          reports, metrics, peers, time spans and graphics to
                          create custom content for any meeting scenario.
                          Transforms unprecedented data into clarifying analysis
                          on any institution."
                button1Text="See Use Cases"
                button1Link="#usecases"
                :galleryImages="FI_SOL_FIN_R"
              />
              <FeatureDetailsCard
                title="FIN Industry Reporter"
                description1="FIN Industry Reporter analyzes the U.S. banking
                          industry in an unprecedented manner."
                description2="Create unique peer groups, segmentations and reports
                          to identify the critical factors driving industry
                          performance.Segmentations can range from market size to core
                          technologies to stratify performance."
                description3="Segmentations can range from market size to core
                          technologies to stratify performance."
                button1Text="See Use Cases"
                button1Link="#usecases"
                :galleryImages="FI_SOL_FIN_I"
              />
            </div>
          </div>
        </section>
        <FiUseCaseDetails></FiUseCaseDetails>
      </div>
    </template>
  </PageCard>
</template>

<script>
// global
import { mapState } from 'vuex'
import PageCard from '@/modules/core/components/layouts/PageCard'
import FeatureDetailsCard from '../components/layouts/FeatureDetailsCard'
import FiUseCaseDetails from './FiUseCaseDetails.vue'
// import FI_SOL_FIN_Q1 from '../../../../static/img/solutionImages/FI_SOL_FIN_Q1.png'
// import FI_SOL_FIN_Q2 from '../../../../static/img/solutionImages/FI_SOL_FIN_Q2.png'
// import FI_SOL_FIN_Q3 from '../../../../static/img/solutionImages/FI_SOL_FIN_Q3.png'
// import FI_SOL_FIN_Q4 from '../../../../static/img/solutionImages/FI_SOL_FIN_Q4.png'
// import FI_SOL_FIN_Q5 from '../../../../static/img/solutionImages/FI_SOL_FIN_Q5.png'
// import FI_SOL_FIN_A1 from '../../../../static/img/solutionImages/FI_SOL_FIN_A1.png'
// import FI_SOL_FIN_A2 from '../../../../static/img/solutionImages/FI_SOL_FIN_A2.png'
// import FI_SOL_FIN_A3 from '../../../../static/img/solutionImages/FI_SOL_FIN_A3.png'
// import FI_SOL_FIN_A4 from '../../../../static/img/solutionImages/FI_SOL_FIN_A4.png'
// import FI_SOL_FIN_A5 from '../../../../static/img/solutionImages/FI_SOL_FIN_A5.png'
// import FI_SOL_FIN_A6 from '../../../../static/img/solutionImages/FI_SOL_FIN_A6.png'
// import FI_SOL_FIN_A7 from '../../../../static/img/solutionImages/FI_SOL_FIN_A7.png'
// import FI_SOL_FIN_A8 from '../../../../static/img/solutionImages/FI_SOL_FIN_A8.png'
// import FI_SOL_FIN_A9 from '../../../../static/img/solutionImages/FI_SOL_FIN_A9.png'
// import FI_SOL_FIN_A10 from '../../../../static/img/solutionImages/FI_SOL_FIN_A10.png'
// import FI_SOL_FIN_A11 from '../../../../static/img/solutionImages/FI_SOL_FIN_A11.png'
// import FI_SOL_FIN_A12 from '../../../../static/img/solutionImages/FI_SOL_FIN_A12.png'
// import FI_SOL_FIN_R1 from '../../../../static/img/solutionImages/FI_SOL_FIN_R1.png'
// import FI_SOL_FIN_R2 from '../../../../static/img/solutionImages/FI_SOL_FIN_R2.png'
// import FI_SOL_FIN_R3 from '../../../../static/img/solutionImages/FI_SOL_FIN_R3.png'
// import FI_SOL_FIN_R4 from '../../../../static/img/solutionImages/FI_SOL_FIN_R4.png'
// import FI_SOL_FIN_R5 from '../../../../static/img/solutionImages/FI_SOL_FIN_R5.png'
// import FI_SOL_FIN_I1 from '../../../../static/img/solutionImages/FI_SOL_FIN_I1.png'
// import FI_SOL_FIN_I2 from '../../../../static/img/solutionImages/FI_SOL_FIN_I2.png'
// import FI_SOL_FIN_I3 from '../../../../static/img/solutionImages/FI_SOL_FIN_I3.png'
// import FI_SOL_FIN_I4 from '../../../../static/img/solutionImages/FI_SOL_FIN_I4.png'

export default {
  name: 'AboutInstitutionAnalytics',
  components: {
    PageCard,
    FeatureDetailsCard,
    FiUseCaseDetails
  },
  data() {
    return {
      FI_SOL_FIN_Q: [
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_SOL_FIN_Q1.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_SOL_FIN_Q2.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_SOL_FIN_Q3.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_SOL_FIN_Q4.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_SOL_FIN_Q5.png'
      ],
      FI_SOL_FIN_A: [
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_SOL_FIN_A1.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_SOL_FIN_A2.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_SOL_FIN_A3.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_SOL_FIN_A4.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_SOL_FIN_A5.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_SOL_FIN_A6.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_SOL_FIN_A7.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_SOL_FIN_A8.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_SOL_FIN_A9.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_SOL_FIN_A10.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_SOL_FIN_A11.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_SOL_FIN_A12.png'
      ],
      FI_SOL_FIN_R: [
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_SOL_FIN_R1.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_SOL_FIN_R2.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_SOL_FIN_R3.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_SOL_FIN_R4.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_SOL_FIN_R5.png'
      ],
      FI_SOL_FIN_I: [
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_SOL_FIN_I1.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_SOL_FIN_I2.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_SOL_FIN_I3.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_SOL_FIN_I4.png'
      ]
    }
  },
  created() {
    if (!this.partnerSite) {
      document.title = 'Institution Analytics'
    }
  },
  computed: {
    ...mapState({
      partnerSite: state => state.partnerSite
    })
  }
}
</script>
<style lang="scss" src="../scss/infoCards.scss"></style>
<style lang="scss" scoped>
.solution-section {
  .soln-img {
    background: url('../../../../static/img/FI_IA.png');
    background-size: cover;
    // margin-bottom: 10px;
  }
}
</style>
