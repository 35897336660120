<template>
  <div>
    <div class="row no-gutters">
      <div class="col-sm-8 pt-3 pl-3 pb-5">
        <div class="row">
          <div class="col-sm-12">
            <h6 class="mb-2">
              Select one or more group to be the focus of the report.
              <span class="text-muted">
                (New Groups can also be created using the button below.)
              </span>
            </h6>
          </div>
        </div>

        <b-card-group deck>
          <b-card
            class="std-border std-list-card"
            body-class="p-0"
            header-class="pl-3"
          >
            <div slot="header">
              <h6 class="mb-0">
                <b>My Saved Groups</b>
                <i class="float-right" v-if="selectedFINQueryCount"
                  >(
                  {{ selectedFINQueryCount }}
                  {{ selectedFINQueryCount | pluralize('group') }} selected )</i
                >
              </h6>
            </div>
            <v-wait for="loadingFINQueries">
              <template slot="waiting">
                <content-placeholders :rounded="true">
                  <content-placeholders-text
                    :lines="15"
                  ></content-placeholders-text>
                </content-placeholders>
              </template>
              <b-list-group class="selectable-list-group multi-selectable">
                <div v-if="filteredFINQueries.length">
                  <b-list-group-item
                    v-for="(query, index) in filteredFINQueries"
                    :key="index"
                    :class="[
                      selectedQueryIDs.includes(query.id) ? 'selected' : '',
                      'p-2'
                    ]"
                    @click="selectQuery(query)"
                  >
                    <span :id="`sg-${query.id}`">
                      {{ query.name
                      }}<span class="text-muted" v-if="isFinApiEnterpriseUser"
                        >[{{ query.id }}]</span
                      >
                    </span>
                    <span
                      class="manage--query-group"
                      v-if="isAdvancedQuery(query)"
                    >
                      <span
                        class="float-right fa fa-close pt-1 px-2 select-remove-icon"
                        title="Remove from selection"
                        @click.stop="unSelectQuery(query)"
                      ></span>
                      <button
                        type="submit"
                        class="float-right btn btn-secondary btn-sm mx-1"
                        @click.stop="deleteFINQuery(query)"
                      >
                        Delete
                      </button>
                      <button
                        type="submit"
                        class="float-right btn btn-primary btn-sm mx-1"
                        @click.stop="editFINQuery(query)"
                      >
                        Edit
                      </button>
                    </span>
                    <b-popover
                      :target="`sg-${query.id}`"
                      triggers="hover"
                      placement="left"
                      v-if="query.description"
                    >
                      {{ query.description }}
                    </b-popover>
                  </b-list-group-item>
                </div>
                <div v-else>
                  <b-list-group-item class="text-center">
                    <i>No Groups found</i>
                  </b-list-group-item>
                </div>
              </b-list-group>
            </v-wait>
          </b-card>

          <b-card
            class="std-border std-list-card"
            body-class="p-0"
            header-class="pl-3"
          >
            <div slot="header">
              <h6 class="mb-0">
                <b>FI Navigator Groups</b>
                <i class="float-right" v-if="selectedNavigatorQueryCount"
                  >(
                  {{ selectedNavigatorQueryCount }}
                  {{ selectedNavigatorQueryCount | pluralize('group') }}
                  selected )</i
                >
              </h6>
            </div>
            <v-wait for="loadingNavigatorQueries">
              <template slot="waiting">
                <content-placeholders :rounded="true">
                  <content-placeholders-text
                    :lines="15"
                  ></content-placeholders-text>
                </content-placeholders>
              </template>
              <b-list-group class="selectable-list-group multi-selectable">
                <div v-if="filteredNavigatorQueries.length">
                  <b-list-group-item
                    v-for="(navigatorQuery, index) in filteredNavigatorQueries"
                    :key="index"
                    :class="[
                      selectedQueryIDs.includes(navigatorQuery.id)
                        ? 'selected'
                        : '',
                      'p-2'
                    ]"
                    @click="selectQuery(navigatorQuery)"
                  >
                    <span :id="`ng-${navigatorQuery.id}`">
                      {{ navigatorQuery.name }}
                    </span>
                    <span
                      class="manage--query-group"
                      v-if="isAdvancedQuery(navigatorQuery)"
                    >
                      <span
                        class="float-right fa fa-close pt-1 px-2 select-remove-icon"
                        title="Remove from selection"
                        @click.stop="unSelectQuery(navigatorQuery)"
                      ></span>
                    </span>
                    <b-popover
                      :target="`ng-${navigatorQuery.id}`"
                      triggers="hover"
                      placement="left"
                      v-if="navigatorQuery.description"
                    >
                      {{ navigatorQuery.description }}
                    </b-popover>
                  </b-list-group-item>
                </div>
                <div v-else>
                  <b-list-group-item class="text-center">
                    <i>No FI Navigator Groups found</i>
                  </b-list-group-item>
                </div>
              </b-list-group>
            </v-wait>
          </b-card>
        </b-card-group>
        <div class="row text-center">
          <div class="col-sm-12">
            <div class="row text-center pt-2">
              <div class="col-sm-12">
                <b-button
                  size=""
                  variant="secondary"
                  class="fw-btn mt-3 mx-3 px-2"
                  @click="resetSelections"
                  >Reset</b-button
                >
                <b-button
                  size=""
                  variant="primary"
                  class="fw-btn mt-3 mx-3 px-2"
                  @click="createFINQuery"
                  >Create Group</b-button
                >
                <router-link
                  tag="button"
                  :to="{ path: 'segmentation' }"
                  class="btn btn-primary fw-btn mt-3 mx-3"
                  :disabled="!canContinue"
                >
                  Continue
                </router-link>
              </div>
            </div>
          </div>
        </div>

        <b-row v-if="queryDetail" class="pt-2">
          <b-col md="12">
            <b-card
              class="std-border metrics-card mt-4"
              body-class="p-1"
              header-class="pl-3"
            >
              <div slot="header">
                <h6 class="mb-0">
                  <b>{{ queryDetail.name }} - Elements</b>
                </h6>
              </div>
              <v-wait for="queryDetailsLoading">
                <template slot="waiting">
                  <content-placeholders :rounded="true">
                    <content-placeholders-text
                      :lines="15"
                    ></content-placeholders-text>
                  </content-placeholders>
                </template>
                <div v-if="queryDetail.elements.length">
                  <b-list-group class="selectable-list-group">
                    <b-list-group-item
                      v-for="(element, index) in queryDetail.elements"
                      :key="index"
                      class="p-2"
                    >
                      {{ element.name }}
                    </b-list-group-item>
                  </b-list-group>
                </div>
                <div v-else>
                  <b-list-group class="selectable-list-group">
                    <b-list-group-item class="p-3 text-center">
                      No elements found.
                    </b-list-group-item>
                  </b-list-group>
                </div>
              </v-wait>
            </b-card>
          </b-col>
        </b-row>
      </div>
      <div class="offset-sm-1 col-sm-3 px-0 std-border">
        <b-card class="" header-class="p-2" body-class="px-3 pt-3">
          <div slot="header">
            <div class="d-flex align-items-center">
              <div>
                <i class="cuis-filter card-icon-font"></i>
              </div>
              <div class="px-2">
                <h6 class="mb-0">
                  <b>Group Filters</b>
                </h6>
                <div class="text-muted">
                  Select options to filter groups.
                </div>
              </div>
            </div>
          </div>
          <div class="font-weight-bold mb-1">
            FI Type:
          </div>
          <div class="report-output-view">
            <multiselect
              track-by="value"
              label="text"
              v-model="fiType"
              :allowEmpty="false"
              :showLabels="false"
              :options="fiTypeOptions"
              class="rw-multi-select aq-multiselect"
            ></multiselect>
          </div>
          <div class="font-weight-bold mb-1 mt-3">
            Group Categories:
          </div>
          <div class="report-output-view">
            <multiselect
              track-by="value"
              label="text"
              v-model="filters"
              :allowEmpty="true"
              :showLabels="false"
              :options="filterOptions"
              class="rw-multi-select aq-multiselect"
              placeholder="Select Categories"
              :multiple="true"
              :close-on-select="false"
              group-label="label"
              group-values="options"
              :group-select="false"
            ></multiselect>
          </div>
        </b-card>
      </div>
    </div>
    <fin-query-modal @queryModalClosed="queryModalClosed"></fin-query-modal>
  </div>
</template>

<script>
// global
import store from '@/store/store'
import router from '@/router/index'
import { mapState, mapGetters, mapMutations } from 'vuex'
import { mapFields } from 'vuex-map-fields'
//ui components
import FINQueryModal from '../components/FINQueryModal'
import Multiselect from 'vue-multiselect'
// api
import finQueriesAPI from '@/modules/advanced_query/api/advanced.query'

export default {
  name: 'IRSelectFIGroup',
  components: {
    'fin-query-modal': FINQueryModal,
    multiselect: Multiselect
  },
  data() {
    return {
      finQueries: [],
      finNavigatorQueries: [],
      queryDetail: null,
      fiTypeOptions: [
        { value: 'bank', text: 'Bank' },
        { value: 'credit_union', text: 'Credit Union' }
      ],
      filters: [],
      filterOptions: [
        {
          label: 'Please select',
          options: [
            { value: 'firmographics', text: 'Firmographics' },
            { value: 'market', text: 'Market Area' },
            { value: 'segmentation', text: 'Segmentation' },
            { value: 'technographics', text: 'Technographics' },
            { value: 'other', text: 'Other/Unassigned' }
          ]
        },
        {
          label: 'Financial Data',
          options: [
            { value: 'performographics', text: 'Performographics' },
            {
              value: 'common-metrics',
              text: 'Common Metrics (Bank/CU)'
            },
            {
              value: 'detailed-metrics',
              text: 'Detailed Metrics'
            },
            {
              value: 'financial-statements',
              text: 'Common Statements (Bank/CU)'
            },
            {
              value: 'detailed-statements',
              text: 'Detailed Statements'
            }
          ]
        },
        {
          label: 'Custom Search',
          options: [
            { value: 'job_search', text: 'Job Search' },
            { value: 'site_search', text: 'Site Search' },
            { value: 'contact_search', text: 'Contact Search' }
          ]
        }
        // {
        //   label: 'Financial Metrics',
        //   options: [
        //     { value: 'profit', text: 'Profit' },
        //     { value: 'growth', text: 'Growth' },
        //     { value: 'risk', text: 'Risk' }
        //   ]
        // },
        // {
        //   label: 'Financial Statements',
        //   options: [
        //     { value: 'balance_sheet', text: 'Balance Sheet' },
        //     { value: 'income_statement', text: 'Income Statement' },
        //     { value: 'deposit_details', text: 'Deposit Details' },
        //     { value: 'credit_quality', text: 'Credit Quality' }
        //   ]
        // },
        // {
        //   label: 'Technographics',
        //   options: [
        //     {
        //       value: 'retail_services',
        //       text: 'Retail Services'
        //     },
        //     {
        //       value: 'business_services',
        //       text: 'Business Services'
        //     },
        //     {
        //       value: 'operating_solutions',
        //       text: 'Operating Solutions'
        //     }
        //   ]
        // }
      ]
    }
  },
  beforeRouteEnter(to, from, next) {
    if (store.getters['Ability/showIndustryReporter']) {
      next()
    } else {
      router.push('/reports/about_industry_reporter')
    }
  },
  created() {
    this.resetAdvancedQueryData()
    this.loadInitData()
  },
  computed: {
    ...mapState('AdvancedQuery', {
      selectedQuery: state => state.selectedQuery
    }),
    ...mapState('IndustryReporter', {
      selectedGroups: state => state.selectedGroups,
      selectedReport: state => state.selectedReport,
      selectedSegment: state => state.selectedSegment
    }),
    ...mapGetters('Ability', ['isFinApiEnterpriseUser']),
    ...mapFields('IndustryReporter', {
      fiType: 'groupFIType'
    }),
    selectedQueryIDs() {
      return this.selectedGroups.map(q => q.id)
    },
    canContinue() {
      return this.selectedGroups.length
    },
    appliedTags() {
      return this.filters.map(filter => filter.value)
    },
    filteredFINQueries() {
      return this.finQueries
        .filter(fq => {
          if (this.appliedTags.length) {
            return (
              fq.fi_type === this.fiType.value &&
              !fq.is_public &&
              fq.taggings.some(tag => this.appliedTags.includes(tag))
            )
          } else {
            return fq.fi_type === this.fiType.value && !fq.is_public
          }
        })
        .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
    },
    filteredNavigatorQueries() {
      return this.finNavigatorQueries.filter(fq => {
        if (this.appliedTags.length) {
          return (
            fq.fi_type === this.fiType.value &&
            fq.taggings.some(tag => this.appliedTags.includes(tag))
          )
        } else {
          return fq.fi_type === this.fiType.value
        }
      })
    },
    selectedFINQueryCount() {
      return this.selectedGroups.filter(q => !q.is_public).length
    },
    selectedNavigatorQueryCount() {
      return this.selectedGroups.filter(q => q.is_public).length
    }
  },
  methods: {
    ...mapMutations('AdvancedQuery', {
      resetAdvancedQueryData: 'resetState',
      setSelectedQuery: 'setSelectedQuery',
      resetSelectedQuery: 'resetSelectedQuery'
    }),
    ...mapMutations('IndustryReporter', [
      'addQueryToGroup',
      'setShowSegments',
      'setSelectedGroups',
      'setSelectedReport',
      'setSelectedSegment',
      'updateSelectedGroup',
      'removeQueryFromGroup'
    ]),

    loadInitData() {
      Promise.all([
        this.loadFINQueries(),
        this.loadFINNavigatorQueries()
      ]).then(() => {})
    },
    loadFINQueries() {
      this.$wait.start('loadingFINQueries')
      finQueriesAPI
        .customFinGroups()
        .then(res => {
          this.finQueries = res.queries
        })
        .finally(() => {
          this.$wait.end('loadingFINQueries')
        })
    },
    loadFINNavigatorQueries() {
      this.$wait.start('loadingNavigatorQueries')
      finQueriesAPI
        .navigatorQueries()
        .then(res => {
          this.finNavigatorQueries = res.navigator_queries
        })
        .finally(() => {
          this.$wait.end('loadingNavigatorQueries')
        })
    },
    selectQuery(query) {
      let isDuplicate = this.selectedGroups.find(fq => fq.id === query.id)

      if (!isDuplicate) {
        this.setSelectedQuery(query)
        this.addQueryToGroup(query)
      }
      this.fetchQueryDetails(query)
    },
    unSelectQuery(query) {
      this.removeQuery(query)
    },
    removeQuery(query) {
      this.removeQueryFromGroup(query)
      this.resetSelectedQuery()
    },
    removeOnFINQueriesList(query) {
      let queryIndex = this.finQueries.findIndex(fq => fq.id === query.id)
      this.finQueries.splice(queryIndex, 1)
    },
    queryModalClosed() {
      this.loadFINQueries()
      this.loadFINNavigatorQueries()
    },
    createFINQuery() {
      this.resetSelectedQuery()
      this.$bvModal.show('finQueryModal')
    },
    isAdvancedQuery(query) {
      return query.group_type === 'AdvancedQuery'
    },
    editFINQuery(query) {
      this.setSelectedQuery(query)
      this.$bvModal.show('finQueryModal')
    },
    deleteFINQuery(query) {
      return this.$dialog
        .confirm(
          {
            title: 'Alert',
            body: `Are you sure, you want to delete Query - ${query.name} ?`
          },
          {
            okText: 'Delete',
            cancelText: 'Cancel'
          }
        )
        .then(() => {
          finQueriesAPI.deleteQuery(query.id).then(res => {
            if (res.success) {
              this.removeQuery(query)
              this.removeOnFINQueriesList(query)
              this.$toasted.global.action_success('Query successfully deleted.')
            }
          })
        })
        .catch(() => {})
    },
    resetSelections() {
      this.setSelectedGroups([])
      this.queryDetail = null
      this.filters = []
    },
    fetchQueryDetails(query) {
      this.$wait.start('queryDetailsLoading')

      this.queryDetail = { id: query.id, name: query.name, elements: [] }

      finQueriesAPI.queryDetails(query.id).then(res => {
        this.queryDetail.elements = res.element_list
        this.$wait.end('queryDetailsLoading')
      })
    },
    updateQueryDetail() {
      if (this.queryDetail) {
        let validQueryDetail = this.selectedGroups
          .map(sg => sg.id)
          .includes(this.queryDetail.id)
        if (!validQueryDetail) {
          this.queryDetail = null
        }
      }
    },
    updateSelectedReport() {
      if (this.selectedReport) {
        let validReport = ['both', this.fiType.value].includes(
          this.selectedReport.fi_type
        )
        if (!validReport) {
          this.setSelectedReport(null)
        }
      }
    },
    updateSelectedSegment() {
      if (this.selectedSegment) {
        let validSegment = ['both', this.fiType.value].includes(
          this.selectedSegment.fi_type
        )

        if (!validSegment) {
          this.setSelectedSegment({ id: null, name: null })
          this.setShowSegments(false)
        }
      }
    }
  },
  watch: {
    fiType: {
      handler: function() {
        this.setSelectedGroups([])
        this.updateQueryDetail()
        this.updateSelectedReport()
        this.updateSelectedSegment()
      }
    },
    filters: {
      handler: function() {
        let selectedGroups = this.selectedGroups.filter(sg => {
          if (this.appliedTags.length) {
            return sg.taggings.some(tag => this.appliedTags.includes(tag))
          } else {
            return true
          }
        })
        this.setSelectedGroups(selectedGroups)
        this.updateQueryDetail()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.manage--query-group {
  display: none;

  .btn {
    border-radius: 0.25rem;
    padding-top: 2px;
    padding-bottom: 2px;
    width: 4rem;
  }
}

.selectable-list-group {
  .list-group-item {
    &:hover {
      .manage--query-group {
        display: inline;
      }
    }
  }
}

.selectable-list-group.multi-selectable {
  .list-group-item {
    .select-remove-icon {
      display: none;
    }

    &.selected {
      .select-remove-icon {
        display: block;
      }
    }
  }
}
</style>
