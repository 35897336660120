import PageMoved from '@/views/pages/PageMoved'

export default {
  path: '/technographics',
  name: 'FiProspectList',
  redirect: '/technographics/product/search',
  component: {
    render(c) {
      return c('router-view')
    }
  },
  meta: { requiredAuth: true },
  children: [
    {
      path: 'product/search',
      name: 'product-search',
      component: () => import('@/views/technographics/product/search'),
      meta: {
        type: 'technographics',
        modulePath: '/technographics/product/search'
      }
    },
    {
      path: 'vendor_report/:id',
      name: 'VendorReport',
      component: () => import('@/views/technographics/vendor/report'),
      meta: {
        type: 'technographics',
        modulePath: '/technographics/product/search'
      }
    },
    {
      path: 'product_report/:id',
      name: 'ProductReport',
      component: () => import('@/views/technographics/product/report'),
      meta: {
        type: 'technographics',
        modulePath: '/technographics/product/search'
      }
    },
    {
      path: 'service_report/:id',
      name: 'ServiceReport',
      component: () => import('@/views/technographics/services/report'),
      meta: {
        type: 'technographics',
        modulePath: '/technographics/product/search'
      }
    },
    {
      path: 'vendor_coverage',
      name: 'VendorCoverage',
      component: () => import('@/views/technographics/VendorCoverage'),
      meta: {
        type: 'technographics',
        modulePath: '/technographics/product/search'
      }
    },
    {
      path: 'solutions',
      name: 'Solutions',
      component: () => import('@/views/pages/solutions'),
      meta: {
        type: 'technographics',
        modulePath: '/technographics/product/search'
      }
    },
    {
      path: 'requests',
      name: 'request-solution',
      component: () => import('@/views/pages/technographic_request'),
      meta: {
        type: 'technographics',
        modulePath: '/technographics/product/search'
      }
    },
    {
      path: 'vendor_requests',
      name: 'request-vendor',
      component: () => import('@/views/pages/vendor_request'),
      meta: {
        type: 'technographics',
        modulePath: '/technographics/product/search'
      }
    },
    {
      path: 'fin_query',
      name: 'AdvancedQuery',
      meta: { type: 'advanced_query' },
      component: () => import('@/modules/advanced_query/pages/Home'),
      children: [
        {
          path: 'query',
          name: 'AQQueryBuilder',
          component: () =>
            import('@/modules/advanced_query/pages/QueryBuilder'),
          meta: {
            type: 'advanced_query',
            modulePath: '/technographics/fin_query/query'
          }
        },
        {
          path: 'report',
          name: 'AQReportWriter',
          component: () =>
            import('@/modules/advanced_query/pages/ReportWriter'),
          meta: {
            type: 'advanced_query',
            modulePath: '/technographics/fin_query/query'
          }
        },
        {
          path: 'download',
          name: 'AQDownload',
          component: () => import('@/modules/advanced_query/pages/Download'),
          meta: {
            type: 'advanced_query',
            modulePath: '/technographics/fin_query/query'
          }
        },
        {
          path: 'conference_import',
          name: 'ConferenceImport',
          component: () =>
            import('@/modules/advanced_query/pages/ContactCleanerTool'),
          meta: {
            type: 'advanced_query',
            modulePath: '/technographics/fin_query/query'
          }
        },
        {
          path: 'crm_data',
          name: 'CRMData',
          component: () => import('@/modules/advanced_query/pages/CrmData'),
          meta: {
            type: 'advanced_query',
            modulePath: '/technographics/fin_query/query'
          }
        }
      ]
    },
    // {
    //   path: '/crm_data/about',
    //   name: 'AboutCRMDataEnrichment',
    //   component: () => import('@/modules/core/pages/AboutCRMDataEnrichment'),
    //   meta: { type: 'crm_data_enrichment', modulePath: '/crm_data/about' }
    // },
    {
      path: 'performographics',
      name: 'performographics_old',
      component: PageMoved
    }
  ]
}
