<template>
  <div>
    <section class=" solution-section">
      <b-row>
        <b-col>
          <div class="text-center">
            <h1 class="mb-4 borderless-banner-title">
              How will you leverage the insights?
            </h1>
          </div>
          <div class="my-3 text-center banner-desc">
            <p>
              Instantly inform your performance enhancement & strategy
            </p>
          </div>
          <div></div>
        </b-col>
      </b-row>
    </section>
    <section class="section path-section" id="usecases">
      <div class="">
        <div class="row container-margin">
          <div class="col-md mb-3 px-2">
            <AnalyticsCard
              title="Benchmarking"
              :image="imgVcPC"
              description="Create innovative peer groupings ranging from FI strategy to technology vendors for benchmarking profitability, growth, risk, and customer offering/technologies."
              button1Text="See Use Cases"
              button1Link="#benchmarking"
              :isRoute="false"
              videoBtnLink=""
              :showReqDemo="true"
            >
            </AnalyticsCard>
          </div>
          <div class="col-md mb-3 px-2">
            <AnalyticsCard
              title="Strategic Planning"
              :image="imgVcSE"
              description="Focus on the primary drivers of franchise value: profit, growth, risk and offering – to instantly generate strengths/opps, primary themes & recommendations."
              button1Text="See Use Cases"
              button1Link="#strategic-planning"
              :isRoute="false"
              videoBtnLink=""
              :showReqDemo="true"
            >
            </AnalyticsCard>
          </div>
        </div>
        <div class="row container-margin">
          <div class="col-md mb-3 px-2">
            <AnalyticsCard
              title="Mergers & Acquisitions"
              :image="imgVcAP"
              description="Generate powerful queries to identify target institutions matching in-depth criteria then leverage instant candidate due diligence covering profit, growth, risk, offering and technologies."
              button1Text="See Use Cases"
              button1Link="#mergers-acquisitions"
              :isRoute="false"
              videoBtnLink=""
              :showReqDemo="true"
            >
            </AnalyticsCard>
          </div>
          <div class="col-md mb-3 px-2">
            <AnalyticsCard
              title="Technology & Offering Planning"
              :image="imgVcMS"
              description="Evaluate vendors with market share and firmographics data. Leverage an offering hierarchy as a release roadmap of 400+ solutions to identify your next-most-likely solutions based on peer group exceptions."
              button1Text="See Use Cases"
              button1Link="#technology-offering-planning"
              :isRoute="false"
              videoBtnLink=""
              :showReqDemo="true"
            >
            </AnalyticsCard>
          </div>
        </div>
      </div>
    </section>
    <!-- Use Case - Feature Detail Cards -->
    <section class="">
      <!-- benchmarking -->
      <div class=" container-margin solution-section" id="benchmarking">
        <div class="ps-2">
          <div class="row card-row">
            <div class="col-9">
              <h1 class="card-title d-inline-block pr-3 banner-title">
                Benchmarking
              </h1>
              <div class="solution-icon same-col usecase-img1"></div>
            </div>
          </div>
          <p class="card-text mb-4 soln-desc">
            Gauge Performance & Customer Offering
          </p>
        </div>
        <FeatureDetailsCard
          title="Performance"
          description1="Performance benchmarking is provided through interactive color-coded decision trees decomposing performance drivers for either profit, growth or risk."
          description2="The analytics provide peer-based classification, cost-benefit, data export, multi-periods and more. Novel metrics range from trust pricing to staff utilization to net non-core funds dependence."
          button1Text="See Solutions"
          button1Link="#"
          :galleryImages="FI_USE_PRF"
        />
        <FeatureDetailsCard
          title="Peer Group Construction"
          description1="Peer group creation is unlimited in FI Analytics. Peer groupings can leverage basic firmographics, market characteristics, technographics, performance, strategy, customer (member) offering and segmentation."
          description2="Segmentations classify institutions on a myriad of factors including vendor diversity, offering innovation, footprint type and many more."
          button1Text="See Solutions"
          button1Link="#"
          :galleryImages="FI_USE_PGR"
        />
        <FeatureDetailsCard
          title="Digital Channels"
          description1="FIN Digital Channels benchmarks retail mobile & online banking delivering innovative metrics on vendor and FI performance."
          description2="The solution produces comparative vendor dashboards on market share, share accretion, churn rates, and much more."
          description3="Institution dashboards cover every U.S. institution segmented by FI type, asset size & other variables."
          button1Text="See Solutions"
          button1Link="#"
          :galleryImages="FI_USE_DIG"
        />
        <FeatureDetailsCard
          title="Offering & Technologies"
          description1="FI Navigator developed an offering hierarchy to prioritize service additions covering hundreds of solutions. Each solution within a category (ex. retail payments) is examined relative to the percentage of peers offering the solution."
          description2="The classification system rewards institutions for earlier provision and even suggests next-most-likely solutions by category."
          button1Text="See Solutions"
          button1Link="#"
          :galleryImages="FI_USE_OFT"
        />
      </div>
      <!-- strategic-planning -->
      <div
        class="section container-margin solution-section"
        id="strategic-planning"
      >
        <div class="ps-2">
          <div class="row card-row">
            <div class="col-9">
              <h1 class="card-title d-inline-block pr-3 banner-title">
                Strategic Planning
              </h1>
              <div class="solution-icon same-col usecase-img2"></div>
            </div>
          </div>
          <p class="card-text mb-4 soln-desc">
            Quantify Your Strategy’s Evolution
          </p>
        </div>
        <FeatureDetailsCard
          title="FI Franchise Value Drivers"
          description1="Whether bank or credit union, an institution’s strategic planning centers primarily on enhancing franchise value."
          description2="FIN Advisor was designed to decompose the four drivers of financial institution franchise value of profit, growth, risk and customer (member) offering to isolate and prioritize specific opportunities – based on peer data exceptions."
          button1Text="See Solutions"
          button1Link="#"
          :galleryImages="FI_USE_VAL"
        />
        <FeatureDetailsCard
          title="Instant Situation Audit"
          description1="FIN Advisor’s Executive Brief provides an Opportunity Assessment detailing institution-specific strengths & opportunities, a Primary Theme depicting the largest opportunity area, and an Action Plan allowing users to address “what now” for any opportunity."
          description2="FIN Advisor ensures your team is aligned and working on the right things."
          button1Text="See Solutions"
          button1Link="#"
          :galleryImages="FI_USE_ISA"
        />
        <FeatureDetailsCard
          title="Performance Recommendations"
          description1="FIN Advisor’s Action Plan is designed to guide the institution’s “what now” to resource specific performance areas. For every opportunity, FIN Advisor recommends a unique set of actions to improve performance."
          description2="FIN Advisor maps performance to 15 performance themes. Theme examples include Core Funding, Fee Generation, or Liquidity Position."
          button1Text="See Solutions"
          button1Link="#"
          :galleryImages="FI_USE_PRC"
        />
        <FeatureDetailsCard
          title="Offering & Tech Recommendations"
          description1="FI Navigator developed the industry’s only offering hierarchy to serve as your roadmap for service additions."
          description2="Covering hundreds of retail and business solutions for every institution, the analysis pinpoints which FI’s provide the service and, in most cases, the vendor-product utilized. It will even recommend your next-most-likely category additions."
          button1Text="See Solutions"
          button1Link="#"
          :galleryImages="FI_USE_OTR"
        />
      </div>
      <!-- mergers-acquisitions -->
      <div
        class="section container-margin solution-section"
        id="mergers-acquisitions"
      >
        <div class="ps-2">
          <div class="row card-row">
            <div class="col-9">
              <h1 class="card-title d-inline-block pr-3 banner-title">
                Mergers & Acquisitions
              </h1>
              <div class="solution-icon same-col usecase-img3"></div>
            </div>
          </div>
          <p class="card-text mb-4 soln-desc">
            Gain an Information Advantage in M&A
          </p>
        </div>
        <FeatureDetailsCard
          title="Data-Based Target Identification"
          description1="FIN Query can filter comprehensive data to quickly find your best fits – identifying synergistic M&A candidates that complement the acquirer."
          description2="Zoom in on institutions with specific performance characteristics, growth potential, shared technologies or advanced digital capabilities to identify potential synergies – in revenue, expense or offering."
          button1Text="See Solutions"
          button1Link="#"
          :galleryImages="FI_USE_TGT"
        />
        <FeatureDetailsCard
          title="Instant Preliminary Due Diligence"
          description1="FIN Advisor serves as an instant preliminary due diligence generated on any acquisition candidate."
          description2="The consultative assessment covers any institution’s profit, growth, risk, offering and technologies – delivering in-depth strengths/weaknesses, cost/benefits, primary themes, technologies-deployed and even offering deficiencies."
          button1Text="See Solutions"
          button1Link="#"
          :galleryImages="FI_USE_PDD"
        />
        <FeatureDetailsCard
          title="Transaction Synergy Insights"
          description1="Gain insight into potential transaction synergies before engaging with an institution candidate with FIN Advisor."
          description2="Verify their strengths align with your institution’s performance opportunities, view their current technology vendor-products, gauge retail/business offering overlap and assess other avenues to ensure an accretive transaction."
          button1Text="See Solutions"
          button1Link="#"
          :galleryImages="FI_USE_SYN"
        />
        <FeatureDetailsCard
          title="Niche Strategy Identification"
          description1="Identifying institutions deftly executing niche strategies can add defensible growth to an acquirer’s portfolio."
          description2="FI Navigator’s innovative offering hierarchy tracks 20+ specialty business lines offered by institutions ranging from aircraft finance to veterinary practice banking."
          description3="Leverage FI Navigator to find and analyze these specialists."
          button1Text="See Solutions"
          button1Link="#"
          :galleryImages="FI_USE_NIC"
        />
      </div>
      <!-- technology-offering-planning -->
      <div
        class="section container-margin solution-section"
        id="technology-offering-planning"
      >
        <div class="ps-2">
          <div class="row card-row">
            <div class="col-9">
              <h1 class="card-title d-inline-block pr-3 banner-title">
                Technology & Offering Planning
              </h1>
              <div class="solution-icon same-col usecase-img4"></div>
            </div>
          </div>
          <p class="card-text mb-4 soln-desc">
            Navigate Digital Transformation with Better Data
          </p>
        </div>
        <FeatureDetailsCard
          title="Customer Offering White Space"
          description1="FIN Advisor’s white space analysis identifies gaps in your offering. The industry’s only offering hierarchy easily facilitates peer comparisons on retail, commercial & operating solutions."
          description2="The analytics recommend next-most-likely solutions by category and the Top Technologies section displays vendor market share for any peer group."
          button1Text="See Solutions"
          button1Link="#"
          :galleryImages="FI_USE_OWS"
        />
        <FeatureDetailsCard
          title="Digital Channels"
          description1="FIN Digital Channels benchmarks retail mobile & online banking delivering innovative metrics on vendor and FI performance."
          description2="The solution produces comparative vendor dashboards on market share, share accretion, churn rates, and much more.  Institution dashboards cover every U.S. institution segmented by FI type, asset size & other variables."
          button1Text="See Solutions"
          button1Link="#"
          :galleryImages="FI_USE_DCS"
        />
        <FeatureDetailsCard
          title="Vendor Evaluations"
          description1="The FinTech Market Reports quickly assess market share & client firmographics by vendor-product combinations."
          description2="FIN VendorFit instantly ranks core processing vendor-product fit for any institution integrating over 100 independent variables ranging from asset size to technology purchase behavior. Bring our data advantage to your vendor evaluations."
          button1Text="See Solutions"
          button1Link="#"
          :galleryImages="FI_USE_EVA"
        />
        <FeatureDetailsCard
          title="Marketing – Website Messaging"
          description1="Financial institutions often release new applications only to be disappointed by low customer utilization."
          description2="Through page ranking, FIN Advisor enables marketers to digitally secret shop peer websites to land on the page introducing their comparable solutions. This capability positions marketing to create the awareness that leads to high utilization."
          button1Text="See Solutions"
          button1Link="#"
          :galleryImages="FI_USE_MWM"
        />
      </div>
    </section>
  </div>
</template>
<script>
import AnalyticsCard from '@/modules/core/components/layouts/AnalyticsCard'
import FeatureDetailsCard from '../components/layouts/FeatureDetailsCard'

import imgVcPC from '../../../../static/img/icon-benchmarking.png'
import imgVcSE from '../../../../static/img/icon-strategic.png'
import imgVcAP from '../../../../static/img/icon-merging-1.png'
import imgVcMS from '../../../../static/img/icon-technology.png'
export default {
  name: 'UseCaseDetails',
  components: {
    FeatureDetailsCard,
    AnalyticsCard
  },
  data() {
    return {
      imgVcPC: imgVcPC,
      imgVcSE: imgVcSE,
      imgVcAP: imgVcAP,
      imgVcMS: imgVcMS,
      FI_USE_ISA: [
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_ISA1.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_ISA2.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_ISA3.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_ISA4.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_ISA5.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_ISA6.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_ISA7.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_ISA8.png'
      ],
      FI_USE_OWS: [
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_OWS1.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_OWS2.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_OWS3.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_OWS4.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_OWS5.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_OWS6.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_OWS7.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_OWS8.png'
      ],
      FI_USE_DCS: [
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_DCS1.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_DCS2.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_DCS3.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_DCS4.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_DCS5.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_DCS6.png'
      ],
      FI_USE_EVA: [
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_EVA1.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_EVA2.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_EVA3.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_EVA4.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_EVA5.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_EVA6.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_EVA7.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_EVA8.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_EVA9.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_EVA10.png'
      ],
      FI_USE_MWM: [
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_MWM1.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_MWM2.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_MWM3.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_MWM4.png'
      ],
      FI_USE_TGT: [
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_TGT1.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_TGT2.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_TGT3.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_TGT4.png'
      ],
      FI_USE_PDD: [
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_PDD1.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_PDD2.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_PDD3.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_PDD4.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_PDD5.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_PDD6.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_PDD7.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_PDD8.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_PDD9.png'
      ],
      FI_USE_SYN: [
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_SYN1.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_SYN2.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_SYN3.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_SYN4.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_SYN5.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_SYN6.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_SYN7.png'
      ],
      FI_USE_NIC: [
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_NIC1.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_NIC2.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_NIC3.png'
      ],
      FI_USE_VAL: [
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_VAL1.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_VAL2.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_VAL3.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_VAL4.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_VAL5.png'
      ],
      FI_USE_PRC: [
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_PRC1.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_PRC2.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_PRC3.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_PRC4.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_PRC5.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_PRC6.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_PRC7.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_PRC8.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_PRC9.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_PRC10.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_PRC11.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_PRC12.png'
      ],
      FI_USE_OTR: [
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_OTR1.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_OTR2.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_OTR3.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_OTR4.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_OTR5.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_OTR6.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_OTR7.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_OTR8.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_OTR9.png'
      ],
      FI_USE_PRF: [
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_PRF1.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_PRF2.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_PRF3.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_PRF4.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_PRF5.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_PRF6.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_PRF7.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_PRF8.png'
      ],
      FI_USE_PGR: [
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_PGR1.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_PGR2.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_PGR3.png'
      ],
      FI_USE_DIG: [
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_DIG1.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_DIG2.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_DIG3.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_DIG4.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_DIG5.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_DIG6.png'
      ],
      FI_USE_OFT: [
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_OFT1.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_OFT2.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_OFT3.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_OFT4.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_OFT5.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_OFT6.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_OFT7.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_OFT8.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_OFT9.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_OFT10.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_OFT11.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_OFT12.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_OFT13.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_OFT14.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_OFT15.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/FI_USE_OFT16.png'
      ]
    }
  }
}
</script>
<style lang="scss" src="../scss/infoCards.scss"></style>
<style lang="scss" scoped>
.usecase-img1 {
  background: url('../../../../static/img/vc-benchmarking.png');
  background-size: cover;
  // margin-bottom: 10px;
}
.usecase-img2 {
  background: url('../../../../static/img/handshake.png');
  background-size: cover;
  // margin-bottom: 10px;
}
.usecase-img3 {
  background: url('../../../../static/img/icon-tactics.png');
  background-size: cover;
  // margin-bottom: 10px;
}
.usecase-img4 {
  background: url('../../../../static/img/icon-pie-chart.png');
  background-size: cover;
  // margin-bottom: 10px;
}
</style>
