<template>
  <div class="card mb-4 analytics-card-body">
    <div class="card-body pb-0">
      <div class="row card-row">
        <div class="col-md-10 col-9">
          <h2 class="card-title analytics-card-title">
            {{ title }}
          </h2>
        </div>
        <div class="col-md-2 col-3">
          <img :src="image" alt="" class="card-img-bottom feature-icon" />
        </div>
      </div>
      <!-- card-text -->
      <p class=" my-3">
        {{ description }}
      </p>
    </div>
    <div class="card-footer pt-0">
      <div class="row multirow-btns-2-cards ">
        <div class="col-md btn-2" v-if="showReqDemo">
          <a
            href="https://www.fi-navigator.com/support/"
            target="_blank"
            class=" text-nowrap text-center cta w-100"
            >Request Demo</a
          >
        </div>
        <div class="col-md btn-2 text-nowrap">
          <a
            :href="button1Link"
            class="cta video-btn w-100 text-center"
            v-if="!isRoute"
            >{{ button1Text }}
          </a>
          <router-link
            tag="button"
            :to="{ path: button1Link }"
            class="cta video-btn w-100 text-center"
            v-else
            >{{ button1Text }}</router-link
          >
        </div>
        <div class="col-md btn-3 text-nowrap" v-if="!showReqDemo">
          <a class="cta video-btn w-100 text-center px-0" @click="showModal()">
            <span
              ><img
                src="../../../../../static/img/video-circle.png"
                alt=""
                class="img-fluid pr-1"
            /></span>
            <span>Quick Video</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'AnalyticsCard',
  props: {
    title: {
      type: String,
      required: true,
      default: '-'
    },
    image: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: false
    },
    button1Text: {
      type: String,
      required: true
    },
    button1Link: {
      type: String,
      required: true
    },
    isRoute: {
      type: Boolean,
      required: false,
      default: true
    },
    videoBtnLink: {
      type: String,
      required: true
    },
    showReqDemo: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      show: false,
      video: `<div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/1024643734?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"  frameborder="0" allow="autoplay; fullscreen; picture-in-picture; clipboard-write" style="position:absolute;top:0;left:0;width:100%;height:100%;" title="VC FI Prospecting + CRM"></iframe></div>`
    }
  },
  methods: {
    showModal() {
      this.$emit('showModal')
    }
  }
}
</script>
<style lang="scss" src="../../scss/infoCards.scss"></style>
