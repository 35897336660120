<template>
  <div>
    <section class=" solution-section">
      <b-row>
        <b-col>
          <div class="text-center">
            <h1 class="mb-4 borderless-banner-title">
              How will you leverage the insights?
            </h1>
          </div>
          <div class="my-3 text-center banner-desc">
            <p>
              Scale and align your B2Bank marketing &amp; sales
            </p>
          </div>
          <div></div>
        </b-col>
      </b-row>
    </section>
    <section class="section path-section" id="usecases">
      <div class="">
        <div class="row container-margin">
          <div class="col-md mb-3 px-2">
            <AnalyticsCard
              title="FI Prospecting & CRM"
              :image="imgVcPC"
              description="The most important step in transforming your B2Bank sales and marketing models is accurately identifying who needs your solutions. The FI Prospecting & CRM solutions ensure that you get it right."
              button1Text="See Use Cases"
              button1Link="#fi-prospecting-crm"
              :isRoute="false"
              videoBtnLink=""
              :showReqDemo="true"
            >
            </AnalyticsCard>
          </div>
          <div class="col-md mb-3 px-2">
            <AnalyticsCard
              title="Sales Engagement"
              :image="imgVcSE"
              description="Leveraging FI Analytics allows you to elevate the dialogue to the C-suite by positioning your products and services as solutions translating to shorter gestation cycles and higher success rates."
              button1Text="See Use Cases"
              button1Link="#sales-engagement"
              :isRoute="false"
              videoBtnLink=""
              :showReqDemo="true"
            >
            </AnalyticsCard>
          </div>
        </div>
        <div class="row container-margin">
          <div class="col-md mb-3 px-2">
            <AnalyticsCard
              title="Account Planning"
              :image="imgVcAP"
              description="Make the vendor-to-advisor transition by deploying FIN Advisor® to align your account planning with your client’s strategic planning - positioning your solutions as C-suite priorities."
              button1Text="See Use Cases"
              button1Link="#account-planning"
              :isRoute="false"
              videoBtnLink=""
              :showReqDemo="true"
            >
            </AnalyticsCard>
          </div>
          <div class="col-md mb-3 px-2">
            <AnalyticsCard
              title="Market Strategy"
              :image="imgVcMS"
              description="The FI Vertical Strategy solutions deliver a competitive advantage to your strategy formulation by harnessing big data to bring clarity to banking's market dynamics."
              button1Text="See Use Cases"
              button1Link="#market-strategy"
              :isRoute="false"
              videoBtnLink=""
              :showReqDemo="true"
            >
            </AnalyticsCard>
          </div>
        </div>
      </div>
    </section>
    <!-- Use Case - Feature Detail Cards -->
    <section class="">
      <!-- fi-prospecting-crm -->
      <div class=" container-margin solution-section" id="fi-prospecting-crm">
        <div class="ps-2">
          <div class="row card-row">
            <div class="col-9">
              <h1 class="card-title d-inline-block pr-3 banner-title">
                FI Prospecting &amp; CRM
              </h1>
              <div class="solution-icon same-col usecase-img1"></div>
            </div>
          </div>
          <p class="card-text mb-4 soln-desc">
            Identify Who Needs Your Solutions
          </p>
        </div>
        <FeatureDetailsCard
          title="Account-Based Marketing"
          description1="FI Navigator provides the most precise ABM available in any vertical combining in-depth performance, technographic, firmographic, market area, intent and segmentation data."
          description2="The FIN Query solution easily (1) filters prospect institutions (2) provides additional institution or contact data and (3) instantly exports the data for laser-focused campaigns."
          button1Text="See Solutions"
          button1Link="#"
          :galleryImages="VC_USE_ABM"
        />
        <FeatureDetailsCard
          title="Contact Data"
          description1="FI Navigator developed unrivaled contact data for financial institution personnel. The team also created industry-specific role and title hierarchies to standardize the data for queries."
          description2="This laser-focus on the U.S. banking vertical allows FI Navigator to easily surpass global contact providers in depth, quality & accuracy of financial institution contact data."
          button1Text="See Solutions"
          button1Link="#"
          :galleryImages="VC_USE_CON"
        />
        <FeatureDetailsCard
          title="CRM Integration"
          description1="Deploy FIN CRM to augment your CRM system’s financial institution account and contact data to provide your team rapid, current context on any institution. Choose custom fields to fit your unique business model."
          description2="Integrate links within your CRM to instantly access institution profiles with account data to inform inbound or outbound calling efforts."
          button1Text="See Solutions"
          button1Link="#"
          :galleryImages="VC_USE_CRM"
        />
        <FeatureDetailsCard
          title="Conference Marketing"
          description1="Banking conferences are an important component of B2Bank marketing.  Advance outreach though is difficult as attendee lists only provide attendee names and their associated institutions shortly before the event."
          description2="FIN Query’s Conference Import rapidly appends contact and account data to these attendee lists to enhance your pre-event marketing."
          button1Text="See Solutions"
          button1Link="#"
          :galleryImages="VC_USE_CNF"
        />
      </div>
      <!-- sales-engagement -->
      <div
        class="section container-margin solution-section"
        id="sales-engagement"
      >
        <div class="ps-2">
          <div class="row card-row">
            <div class="col-9">
              <h1 class="card-title d-inline-block pr-3 banner-title">
                Sales Engagement
              </h1>
              <div class="solution-icon same-col usecase-img2"></div>
            </div>
          </div>
          <p class="card-text mb-4 soln-desc">
            Elevate Your Dialogue to the C-Suite
          </p>
        </div>
        <FeatureDetailsCard
          title="Instant Call Preparation"
          description1="Rapidly access the high-level facts on any institution’s profit, growth, risk, and offering with FIN Profile. The analytics condense comprehensive analysis into fast facts on any institution’s firmographics, performance and known vendor-product relationships."
          description2="For one-click call preparation, integrate FIN Profile as a link in your firm’s CRM system."
          button1Text="See Solutions"
          button1Link="#"
          :galleryImages="VC_USE_ICP"
        />
        <FeatureDetailsCard
          title="Identify Decision Makers"
          description1="Banking’s different. The metrics and technologies are industry-specific. So too are banking personnel’s titles and functional roles."
          description2="FI Navigator developed a banking title/role hierarchy to identify key contacts by seniority and role. This standardization affords users the same precision in targeting decision makers that they enjoy in identifying institutions."
          button1Text="See Solutions"
          button1Link="#"
          :galleryImages="VC_USE_IDM"
        />
        <FeatureDetailsCard
          title="Consultative Selling"
          description1="Client centricity requires focus on client performance objectives. FIN Advisor has identified the top performance and offering needs for every U.S. institution versus peers."
          description2="FIN Advisor facilitates your vendor-to-advisor transition. Engage confidently and credibly with the C-suite positioning your services as solutions to their identified opportunities."
          button1Text="See Solutions"
          button1Link="#"
          :galleryImages="VC_USE_CSL"
        />
        <FeatureDetailsCard
          title="Ensure Perfect Sales Timing"
          description1="The ideal sales scenario is to be meeting with decision makers at the precise moment they’re focused on a performance or offering deficiency – and to offer THE solution."
          description2="FI Navigator can ensure this convergence through prospect queries to ID institutions with specific deficiencies AND the consultative, peer analytics to connect the dots."
          button1Text="See Solutions"
          button1Link="#"
          :galleryImages="VC_USE_PST"
        />
      </div>
      <!-- account-planning -->
      <div
        class="section container-margin solution-section"
        id="account-planning"
      >
        <div class="ps-2">
          <div class="row card-row">
            <div class="col-9">
              <h1 class="card-title d-inline-block pr-3 banner-title">
                Account Planning
              </h1>
              <div class="solution-icon same-col usecase-img3"></div>
            </div>
          </div>
          <p class="card-text mb-4 soln-desc">
            Make the Vendor-to-Advisor Transition
          </p>
        </div>
        <FeatureDetailsCard
          title="Align to Their Strategic Plan"
          description1="Deploy FIN Advisor to align account planning with your client’s strategic planning to elevate your solutions to C-suite priorities."
          description2="Instantly generate a consultative assessment of any institution’s profit, growth, risk, and offering with primary opportunity themes, strengths & weaknesses and even custom solution category recommendations."
          button1Text="See Solutions"
          button1Link="#"
          :galleryImages="VC_USE_ALN"
        />
        <FeatureDetailsCard
          title="ID Cross-Sell Opportunities"
          description1="White space analysis identifies gaps in an institution’s products and services. FI Navigator developed the industry’s only complete solution hierarchy to facilitate peer comparisons on retail, commercial and operating solutions."
          description2="The analytics even recommend next-most-likely solutions in categories ranging from retail payments to cash management."
          button1Text="See Solutions"
          button1Link="#"
          :galleryImages="VC_USE_XSL"
        />
        <FeatureDetailsCard
          title="Identify At-Risk Clients"
          description1="Accurate, early identification of your at-risk clients is vitally important to eliminating the churn that can derail growth projections. FI Navigator positions your organization to pre-empt potential client defections."
          description2="Useful data fields include purchase behavior segmentation (ex. best of breed vs. core centric), other vendor relationships, and more."
          button1Text="See Solutions"
          button1Link="#"
          :galleryImages="VC_USE_ATR"
        />
        <FeatureDetailsCard
          title="ID Other Vendor Relationships"
          description1="You know the solutions your clients currently source from your firm, but who else is working with them?"
          description2="Knowing how your clients source their customer offering and technologies positions you to pinpoint potential client churn, upsell opportunities where your solution is superior or other account-level deltas."
          button1Text="See Solutions"
          button1Link="#"
          :galleryImages="VC_USE_OVR"
        />
      </div>
      <!-- market-strategy -->
      <div
        class="section container-margin solution-section"
        id="market-strategy"
      >
        <div class="ps-2">
          <div class="row card-row">
            <div class="col-9">
              <h1 class="card-title d-inline-block pr-3 banner-title">
                Market Strategy
              </h1>
              <div class="solution-icon same-col usecase-img4"></div>
            </div>
          </div>
          <p class="card-text mb-4 soln-desc">
            Better Data. Better Strategy.
          </p>
        </div>
        <FeatureDetailsCard
          title="Competitive Intelligence"
          description1="Which vendors are gaining share in your solution categories? The FinTech Market Report provides market share for critical fintech solutions by institution types or asset segments."
          description2="FIN Digital Channels calculates churn rates for competitors for selected time spans. Leverage these solutions to inform your market strategy or identify M&A opportunities."
          button1Text="See Solutions"
          button1Link="#"
          :galleryImages="VC_USE_CMP"
        />
        <FeatureDetailsCard
          title="Market Segmentation"
          description1="FI Navigator has developed a myriad of FI segmentations including vendor diversity, retail innovation, failure risk and many others."
          description2="With FIN Industry Reporter, your team can easily build new segmentation schemes delivering insights on the banking vertical. An example would be efficiency ratio levels by core processor utilized as marketing ammo."
          button1Text="See Solutions"
          button1Link="#"
          :galleryImages="VC_USE_SEG"
        />
        <FeatureDetailsCard
          title="Market Research"
          description1="Typically, market research focuses exclusively on market leaders – the top institutions or solutions. FI Navigator covers every U.S. institution’s performance and offerings."
          description2="We don’t estimate market share, rather we calculate it from our independently developed data. Even better the data is updated monthly. See what you’re missing."
          button1Text="See Solutions"
          button1Link="#"
          :galleryImages="VC_USE_MKR"
        />
        <FeatureDetailsCard
          title="Solution White Space Analysis"
          description1="FI Navigator created the banking industry’s only solution hierarchy covering the provision of retail, commercial and operating solutions by every U.S. financial institution – allowing an unprecedented industry view."
          description2="White space identification analyzes existing markets to identify product penetration, growth and solution adjacencies by market segments."
          button1Text="See Solutions"
          button1Link="#"
          :galleryImages="VC_USE_SWS"
        />
      </div>
    </section>
  </div>
</template>
<script>
import AnalyticsCard from '@/modules/core/components/layouts/AnalyticsCard'
import FeatureDetailsCard from '../components/layouts/FeatureDetailsCard'

// import VC_USE_ABM1 from '../../../../static/img/solutionImages/VC_USE_ABM1.png'
// import VC_USE_ABM2 from '../../../../static/img/solutionImages/VC_USE_ABM2.png'
// import VC_USE_ABM3 from '../../../../static/img/solutionImages/VC_USE_ABM3.png'
// import VC_USE_ABM4 from '../../../../static/img/solutionImages/VC_USE_ABM4.png'
// import VC_USE_CON1 from '../../../../static/img/solutionImages/VC_USE_CON1.png'
// import VC_USE_CON2 from '../../../../static/img/solutionImages/VC_USE_CON2.png'
// import VC_USE_CON3 from '../../../../static/img/solutionImages/VC_USE_CON3.png'
// import VC_USE_CRM1 from '../../../../static/img/solutionImages/VC_USE_CRM1.png'
// import VC_USE_CRM2 from '../../../../static/img/solutionImages/VC_USE_CRM2.png'
// import VC_USE_CRM3 from '../../../../static/img/solutionImages/VC_USE_CRM3.png'
// import VC_USE_CNF1 from '../../../../static/img/solutionImages/VC_USE_CNF1.png'
// import VC_USE_CNF2 from '../../../../static/img/solutionImages/VC_USE_CNF2.png'

// import VC_USE_ICP1 from '../../../../static/img/solutionImages/VC_USE_ICP1.png'
// import VC_USE_ICP2 from '../../../../static/img/solutionImages/VC_USE_ICP2.png'
// import VC_USE_ICP3 from '../../../../static/img/solutionImages/VC_USE_ICP3.png'
// import VC_USE_ICP4 from '../../../../static/img/solutionImages/VC_USE_ICP4.png'
// import VC_USE_ICP5 from '../../../../static/img/solutionImages/VC_USE_ICP5.png'
// import VC_USE_ICP6 from '../../../../static/img/solutionImages/VC_USE_ICP6.png'

// import VC_USE_IDM1 from '../../../../static/img/solutionImages/VC_USE_IDM1.png'
// import VC_USE_IDM2 from '../../../../static/img/solutionImages/VC_USE_IDM2.png'
// import VC_USE_IDM3 from '../../../../static/img/solutionImages/VC_USE_IDM3.png'

// import VC_USE_CSL1 from '../../../../static/img/solutionImages/VC_USE_CSL1.png'
// import VC_USE_CSL2 from '../../../../static/img/solutionImages/VC_USE_CSL2.png'
// import VC_USE_CSL3 from '../../../../static/img/solutionImages/VC_USE_CSL3.png'
// import VC_USE_CSL4 from '../../../../static/img/solutionImages/VC_USE_CSL4.png'
// import VC_USE_CSL5 from '../../../../static/img/solutionImages/VC_USE_CSL5.png'
// import VC_USE_CSL6 from '../../../../static/img/solutionImages/VC_USE_CSL6.png'
// import VC_USE_CSL7 from '../../../../static/img/solutionImages/VC_USE_CSL7.png'
// import VC_USE_CSL8 from '../../../../static/img/solutionImages/VC_USE_CSL8.png'
// import VC_USE_CSL9 from '../../../../static/img/solutionImages/VC_USE_CSL9.png'
// import VC_USE_CSL10 from '../../../../static/img/solutionImages/VC_USE_CSL10.png'
// import VC_USE_CSL11 from '../../../../static/img/solutionImages/VC_USE_CSL11.png'
// import VC_USE_CSL12 from '../../../../static/img/solutionImages/VC_USE_CSL12.png'

// import VC_USE_PST1 from '../../../../static/img/solutionImages/VC_USE_PST1.png'
// import VC_USE_PST2 from '../../../../static/img/solutionImages/VC_USE_PST2.png'
// import VC_USE_PST3 from '../../../../static/img/solutionImages/VC_USE_PST3.png'
// import VC_USE_PST4 from '../../../../static/img/solutionImages/VC_USE_PST4.png'
// import VC_USE_PST5 from '../../../../static/img/solutionImages/VC_USE_PST5.png'
// import VC_USE_PST6 from '../../../../static/img/solutionImages/VC_USE_PST6.png'
// import VC_USE_PST7 from '../../../../static/img/solutionImages/VC_USE_PST7.png'
// import VC_USE_PST8 from '../../../../static/img/solutionImages/VC_USE_PST8.png'
// import VC_USE_PST9 from '../../../../static/img/solutionImages/VC_USE_PST9.png'
// import VC_USE_PST10 from '../../../../static/img/solutionImages/VC_USE_PST10.png'
// import VC_USE_PST11 from '../../../../static/img/solutionImages/VC_USE_PST11.png'

// import VC_USE_ALN1 from '../../../../static/img/solutionImages/VC_USE_ALN1.png'
// import VC_USE_ALN2 from '../../../../static/img/solutionImages/VC_USE_ALN2.png'
// import VC_USE_ALN3 from '../../../../static/img/solutionImages/VC_USE_ALN3.png'
// import VC_USE_ALN4 from '../../../../static/img/solutionImages/VC_USE_ALN4.png'
// import VC_USE_ALN5 from '../../../../static/img/solutionImages/VC_USE_ALN5.png'
// import VC_USE_ALN6 from '../../../../static/img/solutionImages/VC_USE_ALN6.png'
// import VC_USE_ALN7 from '../../../../static/img/solutionImages/VC_USE_ALN7.png'
// import VC_USE_ALN8 from '../../../../static/img/solutionImages/VC_USE_ALN8.png'
// import VC_USE_ALN9 from '../../../../static/img/solutionImages/VC_USE_ALN9.png'
// import VC_USE_ALN10 from '../../../../static/img/solutionImages/VC_USE_ALN10.png'
// import VC_USE_ALN11 from '../../../../static/img/solutionImages/VC_USE_ALN11.png'
// import VC_USE_ALN12 from '../../../../static/img/solutionImages/VC_USE_ALN12.png'

// import VC_USE_XSL1 from '../../../../static/img/solutionImages/VC_USE_XSL1.png'
// import VC_USE_XSL2 from '../../../../static/img/solutionImages/VC_USE_XSL2.png'
// import VC_USE_XSL3 from '../../../../static/img/solutionImages/VC_USE_XSL3.png'
// import VC_USE_XSL4 from '../../../../static/img/solutionImages/VC_USE_XSL4.png'
// import VC_USE_XSL5 from '../../../../static/img/solutionImages/VC_USE_XSL5.png'
// import VC_USE_XSL6 from '../../../../static/img/solutionImages/VC_USE_XSL6.png'
// import VC_USE_XSL7 from '../../../../static/img/solutionImages/VC_USE_XSL7.png'
// import VC_USE_XSL8 from '../../../../static/img/solutionImages/VC_USE_XSL8.png'
// import VC_USE_XSL9 from '../../../../static/img/solutionImages/VC_USE_XSL9.png'
// import VC_USE_XSL10 from '../../../../static/img/solutionImages/VC_USE_XSL10.png'
// import VC_USE_XSL11 from '../../../../static/img/solutionImages/VC_USE_XSL11.png'

// import VC_USE_ATR1 from '../../../../static/img/solutionImages/VC_USE_ATR1.png'
// import VC_USE_ATR2 from '../../../../static/img/solutionImages/VC_USE_ATR2.png'
// import VC_USE_ATR3 from '../../../../static/img/solutionImages/VC_USE_ATR3.png'
// import VC_USE_ATR4 from '../../../../static/img/solutionImages/VC_USE_ATR4.png'
// import VC_USE_ATR5 from '../../../../static/img/solutionImages/VC_USE_ATR5.png'

// import VC_USE_OVR1 from '../../../../static/img/solutionImages/VC_USE_OVR1.png'
// import VC_USE_OVR2 from '../../../../static/img/solutionImages/VC_USE_OVR2.png'
// import VC_USE_OVR3 from '../../../../static/img/solutionImages/VC_USE_OVR3.png'

// import VC_USE_CMP1 from '../../../../static/img/solutionImages/VC_USE_CMP1.png'
// import VC_USE_CMP2 from '../../../../static/img/solutionImages/VC_USE_CMP2.png'
// import VC_USE_CMP3 from '../../../../static/img/solutionImages/VC_USE_CMP3.png'
// import VC_USE_CMP4 from '../../../../static/img/solutionImages/VC_USE_CMP4.png'
// import VC_USE_CMP5 from '../../../../static/img/solutionImages/VC_USE_CMP5.png'
// import VC_USE_CMP6 from '../../../../static/img/solutionImages/VC_USE_CMP6.png'
// import VC_USE_CMP7 from '../../../../static/img/solutionImages/VC_USE_CMP7.png'
// import VC_USE_CMP8 from '../../../../static/img/solutionImages/VC_USE_CMP8.png'
// import VC_USE_CMP9 from '../../../../static/img/solutionImages/VC_USE_CMP9.png'
// import VC_USE_CMP10 from '../../../../static/img/solutionImages/VC_USE_CMP10.png'

// import VC_USE_SEG1 from '../../../../static/img/solutionImages/VC_USE_SEG1.png'
// import VC_USE_SEG2 from '../../../../static/img/solutionImages/VC_USE_SEG2.png'
// import VC_USE_SEG3 from '../../../../static/img/solutionImages/VC_USE_SEG3.png'
// import VC_USE_SEG4 from '../../../../static/img/solutionImages/VC_USE_SEG4.png'

// import VC_USE_MKR1 from '../../../../static/img/solutionImages/VC_USE_MKR1.png'
// import VC_USE_MKR2 from '../../../../static/img/solutionImages/VC_USE_MKR2.png'
// import VC_USE_MKR3 from '../../../../static/img/solutionImages/VC_USE_MKR3.png'
// import VC_USE_MKR4 from '../../../../static/img/solutionImages/VC_USE_MKR4.png'
// import VC_USE_MKR5 from '../../../../static/img/solutionImages/VC_USE_MKR5.png'
// import VC_USE_MKR6 from '../../../../static/img/solutionImages/VC_USE_MKR6.png'
// import VC_USE_MKR7 from '../../../../static/img/solutionImages/VC_USE_MKR7.png'
// import VC_USE_MKR8 from '../../../../static/img/solutionImages/VC_USE_MKR8.png'
// import VC_USE_MKR9 from '../../../../static/img/solutionImages/VC_USE_MKR9.png'
// import VC_USE_MKR10 from '../../../../static/img/solutionImages/VC_USE_MKR10.png'
// import VC_USE_MKR11 from '../../../../static/img/solutionImages/VC_USE_MKR11.png'

// import VC_USE_SWS1 from '../../../../static/img/solutionImages/VC_USE_SWS1.png'
// import VC_USE_SWS2 from '../../../../static/img/solutionImages/VC_USE_SWS2.png'
// import VC_USE_SWS3 from '../../../../static/img/solutionImages/VC_USE_SWS3.png'
// import VC_USE_SWS4 from '../../../../static/img/solutionImages/VC_USE_SWS4.png'
// import VC_USE_SWS5 from '../../../../static/img/solutionImages/VC_USE_SWS5.png'
// import VC_USE_SWS6 from '../../../../static/img/solutionImages/VC_USE_SWS6.png'

import imgVcPC from '../../../../static/img/vc-benchmarking.png'
import imgVcSE from '../../../../static/img/handshake.png'
import imgVcAP from '../../../../static/img/icon-pie-chart.png'
import imgVcMS from '../../../../static/img/icon-tactics.png'
export default {
  name: 'UseCaseDetails',
  components: {
    FeatureDetailsCard,
    AnalyticsCard
  },
  data() {
    return {
      imgVcPC: imgVcPC,
      imgVcSE: imgVcSE,
      imgVcAP: imgVcAP,
      imgVcMS: imgVcMS,
      VC_USE_ABM: [
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_ABM1.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_ABM2.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_ABM3.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_ABM4.png'
      ],
      VC_USE_CON: [
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_CON1.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_CON2.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_CON3.png'
      ],
      VC_USE_CRM: [
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_CRM1.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_CRM2.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_CRM3.png'
      ],

      VC_USE_CNF: [
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_CNF1.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_CNF2.png'
      ],
      VC_USE_ICP: [
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_ICP1.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_ICP2.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_ICP3.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_ICP4.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_ICP5.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_ICP6.png'
      ],
      VC_USE_IDM: [
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_IDM1.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_IDM2.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_IDM3.png'
      ],
      VC_USE_CSL: [
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_CSL1.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_CSL2.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_CSL3.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_CSL4.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_CSL5.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_CSL6.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_CSL7.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_CSL8.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_CSL9.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_CSL10.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_CSL11.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_CSL12.png'
      ],
      VC_USE_PST: [
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_PST1.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_PST2.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_PST3.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_PST4.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_PST5.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_PST6.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_PST7.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_PST8.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_PST9.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_PST10.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_PST11.png'
      ],
      VC_USE_ALN: [
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_ALN1.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_ALN2.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_ALN3.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_ALN4.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_ALN5.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_ALN6.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_ALN7.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_ALN8.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_ALN9.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_ALN10.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_ALN11.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_ALN12.png'
      ],
      VC_USE_XSL: [
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_XSL1.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_XSL2.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_XSL3.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_XSL4.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_XSL5.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_XSL6.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_XSL7.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_XSL8.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_XSL9.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_XSL10.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_XSL11.png'
      ],
      VC_USE_ATR: [
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_ATR1.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_ATR2.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_ATR3.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_ATR4.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_ATR5.png'
      ],
      VC_USE_OVR: [
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_OVR1.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_OVR2.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_OVR3.png'
      ],
      VC_USE_CMP: [
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_CMP1.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_CMP2.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_CMP3.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_CMP4.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_CMP5.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_CMP6.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_CMP7.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_CMP8.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_CMP9.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_CMP10.png'
      ],
      VC_USE_SEG: [
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_SEG1.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_SEG2.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_SEG3.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_SEG4.png'
      ],
      VC_USE_MKR: [
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_MKR1.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_MKR2.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_MKR3.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_MKR4.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_MKR5.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_MKR6.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_MKR7.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_MKR8.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_MKR9.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_MKR10.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_MKR11.png'
      ],
      VC_USE_SWS: [
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_SWS1.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_SWS2.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_SWS3.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_SWS4.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_SWS5.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_USE_SWS6.png'
      ]
    }
  }
}
</script>
<style lang="scss" src="../scss/infoCards.scss"></style>
<style lang="scss" scoped>
.usecase-img1 {
  background: url('../../../../static/img/vc-benchmarking.png');
  background-size: cover;
  // margin-bottom: 10px;
}
.usecase-img2 {
  background: url('../../../../static/img/handshake.png');
  background-size: cover;
  // margin-bottom: 10px;
}
.usecase-img3 {
  background: url('../../../../static/img/icon-tactics.png');
  background-size: cover;
  // margin-bottom: 10px;
}
.usecase-img4 {
  background: url('../../../../static/img/icon-pie-chart.png');
  background-size: cover;
  // margin-bottom: 10px;
}
</style>
