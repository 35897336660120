<template>
  <!-- Partner Home Page -->
  <div v-if="partnerSite">
    <PageCard pageName="The Ethos Performance Intelligence" :iconClass="[]">
      <template slot="page_content">
        <div class="row">
          <div class="col-sm-12">
            <p class="px-1">
              Launch the tools below to generate instant insights and drive the
              performance of your institution. Leverage data-driven consultative
              assessments and industry-leading peer analytics to outperform your
              competition.
            </p>
          </div>
        </div>

        <b-row class="about-row">
          <b-col cols="4" class="px-3">
            <b-card
              class="std-border mb-0"
              header-bg-variant="success"
              header-text-variant="white"
              footer-class="d-none"
            >
              <b-card-body class="p-0">
                <div>
                  <span><b>FIN Advisor</b></span>
                  : Instantly generate insights with an action plan to expertly
                  manage your institution.
                </div>

                <router-link
                  tag="button"
                  :to="{ path: '/analytics/advisor/select_institution' }"
                  class="btn btn-secondary fw-btn my-3 p-1 br-1-em"
                  >Launch FIN Advisor</router-link
                >
                <b-card-img
                  src="../../../static/img/ethos/advisor.png"
                  class="rounded-0 my-2"
                />
              </b-card-body>
            </b-card>
          </b-col>
          <b-col cols="4" class="px-1">
            <b-card
              class="std-border mb-0"
              header-bg-variant="success"
              header-text-variant="white"
            >
              <b-card-body class="p-0">
                <div>
                  <span><b>FIN Reporter</b></span>
                  : Instantly generate presentations and custom reports for any
                  meeting scenario.
                </div>

                <router-link
                  tag="button"
                  :to="{ path: '/analytics/reporter/select_institution' }"
                  class="btn btn-secondary fw-btn my-3 p-1 br-1-em"
                  >Launch FIN Reporter</router-link
                >
                <b-card-img
                  src="../../../static/img/ethos/reporter.png"
                  class="rounded-0 my-2"
                />
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </template>
    </PageCard>
  </div>
  <div v-else>
    <InstitutionsDashboard v-if="isFiMenu"></InstitutionsDashboard>
    <VendorsAdvisorsDashboard v-else></VendorsAdvisorsDashboard>
  </div>
</template>

<script>
// global
import { mapState, mapGetters } from 'vuex'
// UI components
import InstitutionsDashboard from './InstitutionsDashboard'
import VendorsAdvisorsDashboard from './VendorsAdvisorsDashboard'
// layout
import PageCard from '@/modules/core/components/layouts/PageCard'

export default {
  name: 'AboutDashboard',
  components: {
    PageCard,
    InstitutionsDashboard,
    VendorsAdvisorsDashboard
  },
  computed: {
    ...mapGetters('Authentication', ['isFiMenu']),
    ...mapState({
      partnerSite: state => state.partnerSite
    })
  },
  created() {
    if (!this.partnerSite) {
      document.title = 'FI Navigator'
    }
  }
}
</script>
