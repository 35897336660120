<template>
  <header class="app-header navbar">
    <!--class="navbar-toggler mobile-sidebar-toggler d-xl-none pl-1" -->
    <!-- <button
      class="navbar-toggler mobile-sidebar-toggler d-lg-none pl-1"
      type="button"
      @click="mobileSidebarToggle"
    >
      <span class="navbar-toggler-icon"></span>
    </button> -->
    <div class="navbar-brand cursor-pointer" @click="navigateDashboard">
      <!-- <i
        class="fa fa-arrow-circle-o-left ethos-link"
        aria-hidden="true"
        @click.stop="navigateToEthos"
        v-if="partnerSite"
      ></i> -->
    </div>
    <!-- <button
      class="navbar-toggler sidebar-toggler  pl-1"
      type="button"
      @click="sidebarToggle"
    >
      <span class="navbar-toggler-icon"></span>
    </button> -->
    <div>
      <h5 class="mb-0 product-title" v-if="!isMobile">
        {{ appNameRouterMatch || 'HOME' }}
      </h5>
    </div>
    <div class="ml-3" v-if="revisionOutdated">
      <b-alert show variant="danger" class="mb-0 py-1 rounded"
        >Application has been upgraded. Please
        <u @click="reloadPage" class="cursor-pointer"> refresh </u> to
        continue.</b-alert
      >
    </div>
    <div class="ml-3" v-if="mockUser">
      <b-alert show variant="danger" class="mb-0 py-1 rounded font-weight-bold">
        Please remember, now you are logged in as {{ currentUserEmail }}.
        <span v-if="partnerSite" class="d-inline-block px-3 cursor-pointer"
          >Click to <u @click="logoutMockUser">Logout</u></span
        >
      </b-alert>
    </div>
    <b-navbar-nav class="ml-auto">
      <b-nav-item-dropdown right no-caret v-if="currentUserGuideAvailable">
        <template slot="button-content">
          <b-img
            src="../../../static/img/help_grey.png"
            class="img-avatar img-help mr-0"
          />
        </template>
        <b-dropdown-item :href="currentUserGuide" target="_blank"
          ><i class="fa fa-download"></i> Download User Guide
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
    <b-navbar-nav class="">
      <HeaderDropdownAccnt />
    </b-navbar-nav>
  </header>
</template>
<script>
import HeaderDropdownAccnt from './HeaderDropdownAccnt.vue'
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'

export default {
  name: 'c-header',
  components: {
    HeaderDropdownAccnt
  },
  computed: {
    ...mapGetters(['isFinancialInstitution']),
    ...mapGetters('Authentication', ['currentUserEmail', 'isFiMenu']),
    ...mapGetters('Ability', ['currentUserGuide']),
    ...mapState({
      partnerSite: state => state.partnerSite,
      revisionOutdated: state => state.revisionOutdated,
      isSidebarExpanded: state => state.isSidebarExpanded
    }),
    ...mapState('Authentication', {
      mockUser: state => state.mockUser
    }),
    ...mapState('Profile', {
      isMobile: state => state.isMobile
    }),
    appNameRouterMatch() {
      let names = {
        prospect_list: 'FI PROSPECTING & CRM',
        analytics: 'FI ANALYTICS',
        market_reports: 'FI VERTICAL STRATEGY',
        strategy: 'FINTECH STRATEGY',
        crm_data_enrichment: 'FI PROSPECTING & CRM',
        digital_channels: 'FI VERTICAL STRATEGY',
        advisor: 'FI ANALYTICS',
        reporter: 'FI ANALYTICS',
        profile: 'FI ANALYTICS',
        admin: 'CLIENT ADMIN',
        industry_report: 'FI VERTICAL STRATEGY',

        fi_institution_analytics: 'INSTITUTION ANALYTICS',
        fi_industry_report: 'INSTITUTION ANALYTICS',
        fi_technographics: 'INSTITUTION ANALYTICS',
        fi_advanced_query: 'INSTITUTION ANALYTICS',
        fi_advisor: 'INSTITUTION ANALYTICS',
        fi_reporter: 'INSTITUTION ANALYTICS',
        fi_profile: 'INSTITUTION ANALYTICS',

        fi_crm_data_enrichment: 'INSTITUTION ANALYTICS',
        fi_technology_analytics: 'TECHNOLOGY ANALYTICS',
        fi_digital_channels: 'TECHNOLOGY ANALYTICS',
        fi_market_reports: 'TECHNOLOGY ANALYTICS',
        fi_vendor_fit: 'TECHNOLOGY ANALYTICS',
        fi_admin: 'CLIENT ADMIN',
        vendor_fit: 'FI PROSPECTING & CRM',
        technographics: 'FI PROSPECTING & CRM',
        advanced_query: 'FI PROSPECTING & CRM'
      }
      let prefix = this.isFiMenu ? 'fi_' : ''
      return names[prefix + this.$route.meta.type]
    },
    currentUserGuideAvailable() {
      return this.currentUserGuide != null
    }
  },
  methods: {
    ...mapActions(['setRevisionOutdated']),
    ...mapMutations(['setSidebarExpanded']),
    ...mapActions('Authentication', ['logOut']),
    sidebarToggle(e) {
      e.preventDefault()
      document.body.classList.toggle('sidebar-hidden')
      this.setSidebarExpanded(!this.isSidebarExpanded)
    },
    sidebarMinimize(e) {
      e.preventDefault()
      document.body.classList.toggle('sidebar-minimized')
    },
    mobileSidebarToggle(e) {
      e.preventDefault()
      document.body.classList.toggle('sidebar-mobile-show')
    },
    companyTypeText() {
      return this.isFinancialInstitution
        ? 'Financial Institutions'
        : 'Vendors & Advisors'
    },
    reloadPage() {
      window.location.reload()
    },
    navigateToEthos() {
      window.location = 'https://ethos.fisglobal.com/marketplace'
    },
    navigateDashboard() {
      if (this.$route.name !== 'Dashboard') {
        this.$router.push('/')
      }
    },
    logoutMockUser() {
      this.logOut({ skipRedirect: false, silent: false })
    },
    clearSessionDetails() {
      this.logOut({ skipRedirect: false, silent: true })
    }
  }
}
</script>

<style lang="scss" scoped>
.prime-alert {
  margin-top: 15px;
}

.navbar-brand {
  border-right: 1px solid #a4b7c1;
}
.product-title {
  padding-left: 12px;
}
</style>
