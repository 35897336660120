import { render, staticRenderFns } from "./Layout.vue?vue&type=template&id=fe6f72e6&scoped=true&"
import script from "./Layout.vue?vue&type=script&lang=js&"
export * from "./Layout.vue?vue&type=script&lang=js&"
import style0 from "../scss/digital.channels.scss?vue&type=style&index=0&lang=scss&"
import style1 from "./Layout.vue?vue&type=style&index=1&id=fe6f72e6&lang=scss&scoped=true&"
import style2 from "./Layout.vue?vue&type=style&index=2&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fe6f72e6",
  null
  
)

export default component.exports