import { render, staticRenderFns } from "./CrmData.vue?vue&type=template&id=a9c03732&scoped=true&"
import script from "./CrmData.vue?vue&type=script&lang=js&"
export * from "./CrmData.vue?vue&type=script&lang=js&"
import style0 from "../../core/scss/infoCards.scss?vue&type=style&index=0&lang=scss&"
import style1 from "./CrmData.vue?vue&type=style&index=1&id=a9c03732&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a9c03732",
  null
  
)

export default component.exports