<template>
  <div class="px-4">
    <b-card class="page-card digital-channels-report" header-class="dc-header">
      <div slot="header">
        <b-row>
          <b-col cols="12">
            <div class="page-header-left header-align">
              <div class="d-flex align-items-baseline">
                <div class="pr-1 pb-2 header-icon">
                  <i class="cuis-chart-pie card-icon-font"></i>
                </div>
                <div class="pr-1 pb-2">
                  <h5 class="mb-0"><b>Fintech Market Reports -</b></h5>
                </div>
                <div class="report-title">
                  <multiselect
                    :allowEmpty="false"
                    :showLabels="false"
                    :options="vendorReports"
                    :multiple="false"
                    :close-on-select="true"
                    :searchable="false"
                    class="min-width-20 cursor-pointer dc-report-select"
                    v-model="selectedVendorReport"
                  >
                  </multiselect>
                </div>
              </div>
              <div
                class="text-right profile-icon-tray d-flex align-items-flex-start"
              >
                <i
                  @click="printVendorReport"
                  title="Print Report"
                  class="cuis-print card-icon-font px-1 xlsx-link cursor-pointer"
                ></i>
                <v-wait for="downloadingReport">
                  <template slot="waiting">
                    <i class="fa fa-lg px-2 fa-spinner fa-spin"></i>
                  </template>
                  <i
                    @click="downloadVendorReport"
                    class="cuis-file-excel card-icon-font pr-2 cursor-pointer xlsx-link"
                    title="Download xlsx"
                  ></i>
                </v-wait>
                <div>
                  <i
                    class="fa fa-cog fa-lg cursor-pointer xlsx-link reset-vertical-align"
                    :disabled="isAsideOpen"
                    @click="showSettings"
                  ></i>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
      <div class="dc-pdf-header">
        <div class="pb-3">
          <div class="row">
            <div class="col-sm-12">
              <img src="/logo.png" class="report-logo" />
            </div>
          </div>
          <div class="row">
            <div class="pl-3 pt-2">
              <h3>
                <b>Fintech Market Reports - {{ selectedVendorReport }}</b>
              </h3>
            </div>
          </div>
        </div>
      </div>
      <component
        v-bind:is="reportComponent()"
        :ref="this.selectedVendorReport.replace(/\W/g, '')"
      ></component>
    </b-card>
  </div>
</template>

<script>
import store from '@/store/store'
import router from '@/router/index'
import PageSettings from '@/mixins/PageSettings'
import MarketShare from '../components/MarketShare'
import Firmographics from '../components/Firmographics'
import { mapGetters, mapMutations, mapActions, mapState } from 'vuex'
// api
// ui components
import Multiselect from 'vue-multiselect'
// import { mapFields } from 'vuex-map-fields'
// utilities
export default {
  name: 'FintechVendorReportsLayout',
  components: {
    Multiselect,
    MarketShare,
    Firmographics
  },
  mixins: [PageSettings],
  beforeRouteEnter(to, from, next) {
    if (store.getters['Ability/showMarketReports']) {
      next()
    } else {
      router.push('/reports/about_market_reports')
    }
  },
  beforeCreate() {
    // this.$store.dispatch('setAside', true)
    document.title = 'FinTech Market Reports'
  },
  beforeDestroy() {
    document.body.classList.add('aside-menu-hidden')
    this.$store.dispatch('setAsideToogle', false)
  },
  // mounted() {
  //   // this.trackReportView()
  //   // this.loadInitData()
  // },
  data() {
    return {
      selectedVendorReport: 'Market Share',
      vendorReports: [
        'Market Share',
        'Client Size',
        'Client Profit',
        'Client Growth',
        'Client Risk'
      ],
      orderSolution: false,
      userSolutionLoaded: false,
      allSolutionLoaded: false,
      firmographics: {
        'Client Size': 1,
        'Client Profit': 2,
        'Client Growth': 3,
        'Client Risk': 4
      }
    }
  },
  computed: {
    ...mapGetters(['showAside', 'isAsideOpen']),
    ...mapGetters('marketShareReport', ['getSelectedVendorReport']),
    ...mapState('marketShareReport', ['downloadReportData']),
    headerFiType() {
      switch (this.fiType) {
        case 0:
          return 'FIs'
        case 1:
          return 'Banks'
        case 2:
          return 'Credit Unions'
        default:
          return 'FIs'
      }
    }
  },

  methods: {
    ...mapMutations('marketShareReport', [
      'updateFirmographicId',
      'setSelectedVendorReport'
    ]),
    ...mapActions('marketShareReport', ['getFirmographics', 'downloadReport']),
    reportComponent() {
      if (this.selectedVendorReport === 'Market Share') return MarketShare
      else return Firmographics
    },
    printVendorReport() {
      setTimeout(
        function() {
          this.$nextTick(() => {
            this.$toasted.clear()

            window.print()
            return
          })
        }.bind(this),
        250
      )
    },
    downloadVendorReport() {
      this.downloadReport()
    }
  },
  watch: {
    selectedVendorReport: {
      immediate: true,
      handler: function(newVal) {
        if (newVal) {
          this.setSelectedVendorReport(newVal)
          if (this.selectedVendorReport != 'Market Share') {
            this.updateFirmographicId(
              this.firmographics[this.selectedVendorReport]
            )
            this.getFirmographics()
          }
        }
      }
    }
  }
}
</script>
<style
  lang="scss"
  src="../../digital_channels/scss/digital.channels.scss"
></style>
<style lang="scss" scoped>
.header-align {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.reset-vertical-align {
  vertical-align: 0;
}
.dc-pdf-header {
  display: none;
}
</style>
<style lang="scss">
@media print {
  @page {
    size: portrait;
  }
  .digital-channels-report .market-report-container {
    .product-link {
      text-decoration: none;
    }
    .small-font-for-print {
      font-size: 0.65rem;
      td {
        padding: 0.75rem 0.2rem;
      }
    }
    .less-padding-for-print {
      td {
        padding: 0.75rem 0.2rem;
      }
    }
    .landscape-trend-table {
      table td {
        padding: 0.75rem 0.25rem;
      }
      table {
        overflow: hidden;
      }
      .trend-col-1 {
        min-width: 0;
      }
      .trend-col-others {
        min-width: 0;
      }
      .rank-col {
        display: none;
      }
    }
  }

  .digital-channels-report {
    border: solid 3px #e4e5e6;
    .dc-pdf-header {
      display: block;
    }

    .card-header.dc-header {
      display: none;
    }

    table {
      thead {
        display: table-row-group !important;
      }

      tr {
        page-break-before: avoid;
        page-break-after: avoid;
        page-break-inside: unset;
      }
    }
  }
}
.multiselect__element {
  span {
    white-space: pre;
  }
}
</style>
