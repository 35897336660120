<template>
  <PageCard
    pageName="FIN Profile"
    :iconClass="['fa', 'icon-screen-smartphone', 'fa-lg']"
    :showHeader="false"
  >
    <template slot="page_content">
      <div class="homepage-content">
        <section class="  solution-section">
          <div class="">
            <div class="section container-margin" id="1">
              <FeatureDetailsCard
                title="FIN Profile"
                description1="Access FIN Profile® to instantly get up to speed on any institution."
                description2="Quickly access high-level facts on profit, growth, risk, & offering. Condense in-depth analyses into fast facts on any FI’s firmographics, performance & known vendor-products deployed. Engage FI prospects with the confidence enabled by FIN Profile’s rapid due diligence."
                button1Text="See Use Cases"
                :button1Link="usecaseLink"
                :galleryImages="VC_SOL_FIN_P"
              />
            </div>
          </div>
        </section>
      </div>
    </template>
  </PageCard>
</template>

<script>
// global
import { mapState, mapGetters } from 'vuex'
// layout
import PageCard from '@/modules/core/components/layouts/PageCard'
import FeatureDetailsCard from '../components/layouts/FeatureDetailsCard'

export default {
  name: 'AboutAdvisor',
  components: {
    PageCard,
    FeatureDetailsCard
  },
  data() {
    return {
      VC_SOL_FIN_P: [
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_SOL_FIN_P1.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_SOL_FIN_P2.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_SOL_FIN_P3.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_SOL_FIN_P4.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_SOL_FIN_P5.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_SOL_FIN_P6.png'
      ]
    }
  },
  created() {
    if (!this.partnerSite) {
      document.title = 'FIN Profile'
    }
  },
  computed: {
    ...mapState({
      partnerSite: state => state.partnerSite
    }),
    ...mapGetters('Authentication', ['isFiMenu']),
    usecaseLink() {
      return this.isFiMenu
        ? '/institution_analytics/about'
        : '/analytics/analytics/about'
    }
  }
}
</script>
<style lang="scss" src="../scss/infoCards.scss"></style>
<style lang="scss" scoped>
.homepage-content {
  background-size: cover;
}
</style>
