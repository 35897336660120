<template>
  <div class="card mb-4 border-0 no-bg-card">
    <div class="card-body soln-card">
      <div class="row">
        <div class="col-lg-4 col-12 soln-card-info">
          <div>
            <h4 class="card-title soln-info-title">{{ title }}</h4>
          </div>

          <div class="card-text">
            <p>{{ description1 }}</p>
            <p>{{ description2 }}</p>
            <p>{{ description3 }}</p>
          </div>

          <!-- Single Row Buttons -->
          <div class="row soln-info-btn-row">
            <div class="col-md-6 mb-3 soln-btn-1">
              <a
                href="https://www.fi-navigator.com/support/"
                target="_blank"
                class=" text-nowrap text-center cta w-100"
                >Request Demo</a
              >
            </div>
            <div class="col-md mb-3 soln-btn-2">
              <router-link
                :to="button1Link"
                class="cta soln-btn w-100 text-center text-nowrap "
              >
                {{ button1Text }}
              </router-link>
            </div>
          </div>
        </div>
        <div class="col-lg-8 col-12">
          <b-carousel
            id="carousel-1"
            :interval="2000"
            controls
            :indicators="true"
            background="none"
            img-width="1024"
            img-height="480"
          >
            <b-carousel-slide
              v-for="(image, index) in galleryImages"
              :key="index"
            >
              <template #img>
                <img
                  class="d-block img-fluid w-100 gallery-img"
                  width="1024"
                  height="480"
                  :src="galleryImages[index]"
                  alt="image slot"
                />
              </template>
            </b-carousel-slide>
          </b-carousel>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FeatureDetailsCard',
  props: {
    title: {
      type: String,
      required: true,
      default: '-'
    },
    galleryImages: {
      type: Array,
      required: true
    },
    description1: {
      type: String,
      required: false
    },
    description2: {
      type: String,
      required: false
    },
    description3: {
      type: String,
      required: false
    },
    button1Text: {
      type: String,
      required: true
    },
    button1Link: {
      type: String,
      required: true
    }
  }
}
</script>
<style lang="scss" src="../../scss/infoCards.scss"></style>
<style lang="scss">
.carousel {
  background: none !important;
}
.gallery-img {
  border: 1px solid #cccccc;
}
.carousel-indicators {
  position: relative !important;
}
.carousel .carousel-indicators li {
  width: 5px !important;
  height: 5px !important;
  border-radius: 100%;
  background-color: black;
}
</style>
