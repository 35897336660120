<template>
  <partner-admin-page-card
    pageName="Partner Users"
    iconClass="fa fa-users fa-lg"
  >
    <v-wait for="fetchingUsers">
      <template slot="waiting">
        <content-placeholders :rounded="true">
          <content-placeholders-text :lines="20"></content-placeholders-text>
        </content-placeholders>
      </template>
      <div class="row">
        <div class="col-sm-3 offset-sm-9 mb-3">
          <b-input-group>
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fa fa-search fa-lg"></i>
              </span>
            </div>
            <b-form-input
              type="text"
              v-model="filterText"
              placeholder="Search"
              ref="search"
            ></b-form-input>
          </b-input-group>
        </div>
      </div>
      <div class="col-sm-12">
        <b-table
          :items="listingUsers"
          :fields="tableFields"
          :show-empty="true"
          :current-page="currentPage"
          :per-page="perPage"
          :filter="filterText"
          :filter-included-fields="filterOn"
          @filtered="afterFilter"
          empty-text="No records found."
          responsive="md"
          striped
          hover
        >
          <template v-slot:cell(first_name)="data">
            {{ data.item.first_name }} {{ data.item.last_name }}
          </template>
          <template v-slot:cell(actions)="data">
            <b-button
              size="sm"
              variant="primary"
              @click="setSelectedUser(data.item)"
              v-b-modal.mockConfirmation
              >Mock</b-button
            >
            <router-link
              :to="{
                name: 'PartnerAdminEditUser',
                params: { id: data.item.id }
              }"
              tag="div"
              class="btn btn-primary btn-sm mx-2"
            >
              Edit
            </router-link>
            <div
              class="btn btn-secondary btn-sm mx-2"
              @click="setSelectedUser(data.item)"
              v-b-modal.deleteConfirmation
            >
              Delete
            </div>
          </template>
        </b-table>
        <b-row>
          <b-col md="6">
            <b-pagination
              :total-rows="filteredUsers ? filteredUsers.length : totalRows"
              :per-page="perPage"
              v-model="currentPage"
            >
            </b-pagination>
          </b-col>
        </b-row>
      </div>

      <b-modal
        id="deleteConfirmation"
        ref="deleteConfirmation"
        title="Delete User"
        @ok="deleteUser"
        @cancel="resetSelectedUser"
      >
        <p>Are you sure, want to delete the user?</p>
      </b-modal>

      <b-modal
        id="mockConfirmation"
        ref="mockConfirmation"
        title="Mock User"
        @ok="mockUser"
        @cancel="resetSelectedUser"
      >
        <p>Are you sure, want to mock the user?</p>
      </b-modal>
    </v-wait>
  </partner-admin-page-card>
</template>

<script charset="utf-8">
// global
import Vue from 'vue'
import store from '@/store/store'
import router from '@/router/index'
import { mapActions, mapMutations } from 'vuex'
// api
import partnerUsersAPI from '@/api/finapps/partner_admin/users'

export default {
  name: 'PartnerUsers',
  beforeCreate() {
    let hasPermission = store.getters['Ability/managePartnerAdminBoard']

    if (!hasPermission) {
      Vue.toasted.show('Page access restricted. Please contact admin.', {
        icon: 'chain-broken',
        type: 'error'
      })
      router.push('/dashboard')
    }
  },
  created() {
    this.fetchPartnerUsers()
  },
  data() {
    return {
      users: [],
      currentPage: 1,
      perPage: 20,
      selectedUser: null,
      filterText: null,
      filteredUsers: null,
      filterOn: ['first_name', 'email', 'company', 'phone', 'role_name']
    }
  },
  computed: {
    listingUsers() {
      return this.users.filter(user => !user.isDeleted)
    },
    totalRows() {
      return this.listingUsers.length
    },
    tableFields() {
      return [
        { key: 'first_name', label: 'Name', sortable: true },
        { key: 'email', label: 'Email', sortable: true },
        { key: 'company', label: 'Company', sortable: true },
        { key: 'phone', label: 'Phone', sortable: true },
        { key: 'role_name', label: 'Role', sortable: true },
        { key: 'actions', label: 'Actions', sortable: false }
      ]
    }
  },
  methods: {
    ...mapActions('ThirdPartyServices', ['setUserDataToChameleon']),
    ...mapMutations(['setFinancialInstitutionBoolean']),
    ...mapMutations('Ability', [
      'setUserRole',
      'setUserAbility',
      'setEnabledModules',
      'setEnabledPaths',
      'setEnterprisePermissions',
      'setActiveClientPricingAvailable',
      'setModulePermissions'
    ]),
    ...mapMutations('Authentication', ['setUserData', 'setMockUser']),
    afterFilter: function(filteredItems) {
      this.filteredUsers = filteredItems
      this.currentPage = 1
    },
    setSelectedUser(user) {
      this.selectedUser = user
    },
    resetSelectedUser() {
      this.selectedUser = null
    },
    fetchPartnerUsers() {
      this.$wait.start('fetchingUsers')

      partnerUsersAPI
        .all()
        .then(res => {
          res.users.forEach(user => (user.isDeleted = false))
          this.users = res.users
        })
        .finally(() => {
          this.$wait.end('fetchingUsers')
        })
    },
    deleteUser(evt) {
      evt.preventDefault()

      partnerUsersAPI.deleteUser(this.selectedUser.id).then(res => {
        this.setSelectedUser({})
        this.maskUserAsDeleted(res.user)
        this.$refs.deleteConfirmation.hide()
        Vue.toasted.show(res.message, {
          icon: 'user-circle',
          type: 'success'
        })
      })
    },
    maskUserAsDeleted(userId) {
      let user = this.users.find(user => user.id === userId)
      if (user) user.isDeleted = true
    },
    mockUser() {
      partnerUsersAPI.mockUser(this.selectedUser.id).then(
        res => {
          this.setUserData(res)
          this.setFinancialInstitutionBoolean()
          this.setUserRole(res.role.name)
          this.setUserAbility()
          this.setModulePermissions(res.module_permission)
          this.setEnabledModules(res.ui_modules)
          this.setEnabledPaths(res.ui_modules)
          this.setActiveClientPricingAvailable(res.active_client_pricing)
          this.setEnterprisePermissions(res.enterprise_permissions)
          this.setUserDataToChameleon()
          this.setMockUser(true)
          this.$router.push('/')
        },
        error => {
          this.$toasted.global.invalid(error.msg)
        }
      )
    }
  }
}
</script>

<style lang="scss" scoped></style>
