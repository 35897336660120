<template>
  <PageCard
    pageName="FIN Advisor"
    :iconClass="['fa', 'icon-screen-smartphone', 'fa-lg']"
    :showHeader="false"
  >
    <template slot="page_content">
      <div class="homepage-content">
        <section class="  solution-section">
          <div class="">
            <div class="section container-margin" id="1">
              <FeatureDetailsCard
                title="FIN Advisor"
                description1="Deploy FIN Advisor to align your account planning with your client’s strategic planning – positioning your solutions as C-suite priorities."
                description2="Instantly generate a complete consultative assessment of any FI’s profit, growth, risk, &amp; offering – generating primary opportunity themes, specific strengths/weaknesses and even custom solution category recommendations."
                button1Text="See Use Cases"
                :button1Link="usecaseLink"
                :galleryImages="VC_SOL_FIN_A"
              />
            </div>
          </div>
        </section>
      </div>
    </template>
  </PageCard>
</template>

<script>
// global
import { mapState, mapGetters } from 'vuex'
// layout
import PageCard from '@/modules/core/components/layouts/PageCard'
import FeatureDetailsCard from '../components/layouts/FeatureDetailsCard'

export default {
  name: 'AboutAdvisor',
  components: {
    PageCard,
    FeatureDetailsCard
  },
  data() {
    return {
      VC_SOL_FIN_A: [
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_SOL_FIN_A1.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_SOL_FIN_A2.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_SOL_FIN_A3.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_SOL_FIN_A4.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_SOL_FIN_A5.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_SOL_FIN_A6.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_SOL_FIN_A7.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_SOL_FIN_A8.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_SOL_FIN_A9.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_SOL_FIN_A10.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_SOL_FIN_A11.png',
        process.env.VUE_APP_IMG_CLOUDFRONT_URL + '/VC_SOL_FIN_A12.png'
      ]
    }
  },
  created() {
    if (!this.partnerSite) {
      document.title = 'FIN Advisor'
    }
  },
  computed: {
    ...mapState({
      partnerSite: state => state.partnerSite
    }),
    ...mapGetters('Authentication', ['isFiMenu']),
    usecaseLink() {
      return this.isFiMenu
        ? '/institution_analytics/about'
        : '/analytics/analytics/about'
    }
  }
}
</script>
<style lang="scss" src="../scss/infoCards.scss"></style>
<style lang="scss" scoped>
.homepage-content {
  background-size: cover;
}
</style>
