<template>
  <div>
    <div v-if="reportLoading">
      <content-placeholders :rounded="true">
        <content-placeholders-text :lines="15"></content-placeholders-text>
      </content-placeholders>
    </div>
    <div v-else>
      <div v-if="solutionId === null">
        <div class="overlay-text">
          No Solutions found.
        </div>
        <div class="blurred-report blur-img-container mt-2"></div>
      </div>

      <div v-else>
        <div class="market-report-header" v-if="isSolutionSelected">
          <div class="row">
            <div class="col-sm-12">
              <div class="">
                <div class="d-flex align-items-baseline report-title">
                  <div class="pr-2">Technographic -</div>
                  <div>
                    <multiselect
                      track-by="id"
                      label="displayText"
                      :allowEmpty="false"
                      :showLabels="false"
                      :options="getTechnographicsOptions"
                      :searchable="false"
                      class="min-width-20 cursor-pointer dc-report-select market_report"
                      id="technographic"
                      :value="selectedSolution"
                      @select="updateTechnographic"
                    >
                      <template slot="singleLabel" slot-scope="{ option }">
                        <div class="d-flex">
                          <div class="option-text">
                            {{ option.name }}
                          </div>
                        </div>
                      </template>
                    </multiselect>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6 report-subtitle">
              <div class="">
                {{ reportSubTitle }}
              </div>
            </div>
            <div
              class="col-sm-6 text-right selected-filters-message"
              v-if="this.selectedFiltersMessage.trim() !== ``"
            >
              <span class="font-weight-bold text-muted">
                <i class="fa fa-filter" aria-hidden="true"></i>
                Filters:</span
              >
              <span class="pl-2">{{ this.selectedFiltersMessage }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="market-report-container" v-if="isSolutionSelected">
        <div class="table-responsive">
          <table class="table table-striped">
            <thead v-if="regulatedTableData.length">
              <tr>
                <th>Rank</th>
                <th @click="sortByColumn('vendor')">
                  <span>Vendor Name</span>
                  <span>
                    <i
                      :class="[
                        'fa fa-regular sort-icon',
                        getSortIconClass('vendor')
                      ]"
                    >
                    </i>
                  </span>
                </th>
                <th @click="sortByColumn('product')" v-if="showProduct">
                  <span>Product Name</span>
                  <span>
                    <i
                      :class="[
                        'fa fa-regular sort-icon',
                        getSortIconClass('product')
                      ]"
                    >
                    </i>
                  </span>
                </th>
                <th @click="sortByColumn('market_measure')" class="text-right">
                  <span>Market Total</span>
                  <span>
                    <i
                      :class="[
                        'fa fa-regular sort-icon',
                        getSortIconClass('market_measure')
                      ]"
                    >
                    </i>
                  </span>
                </th>
                <th @click="sortByColumn('market_percent')" class="text-right">
                  <span>% of Market</span>
                  <span>
                    <i
                      :class="[
                        'fa fa-regular sort-icon',
                        getSortIconClass('market_percent')
                      ]"
                    >
                    </i>
                  </span>
                </th>
                <th class="text-right">% of Market</th>
              </tr>
            </thead>
            <tbody ref="reportTableBody" v-if="regulatedTableData.length">
              <tr v-for="(data, rank) in regulatedTableData" :key="rank">
                <td>
                  {{ rank + 1 }}
                </td>
                <td class="name-column" :title="data.vendor">
                  {{ data.vendor }}
                </td>
                <td
                  v-if="showProduct"
                  class="name-column"
                  :title="data.product"
                >
                  {{ data.product }}
                </td>
                <td class="text-right" v-if="showLink(data)">
                  <router-link
                    :to="{
                      name: 'ProductReport',
                      params: { id: data.product_id },
                      query: filterQuery(data)
                    }"
                    class="cursor-pointer product-link"
                  >
                    {{ data.market_measure | numberFormat }}
                  </router-link>
                </td>
                <td class="text-right" v-else>
                  {{ data.market_measure | numberFormat }}
                </td>
                <td class="text-right">
                  {{ parseFloat(data.market_percent) | formatValue('P1') }}
                </td>
                <td
                  class="p-0"
                  :rowspan="regulatedTableData.length + 1"
                  v-if="rank === 0"
                >
                  <div
                    class="report-chart-container"
                    ref="reportChartContainer"
                    v-if="showChart"
                  >
                    <div id="report-chart-ms" ref="reportChart"></div>
                  </div>
                </td>
              </tr>
              <tr class="report-footer">
                <td class="rank-col"></td>
                <td :colspan="showProduct ? 2 : 1">Grand Total</td>
                <td class="text-right">
                  {{ parseFloat(reportTotal.market_measure) | numberFormat }}
                </td>
                <td class="text-right">100%</td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="5">
                  <div class="text-center text-muted">No Records found</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script type="text/javascript">
// global
import Vue from 'vue'
// ui components
import Multiselect from 'vue-multiselect'
import Highcharts from 'highcharts'
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'

// mixins
import ReportUtil from '../mixins/reportUtil'
export default {
  name: 'market-share-report',
  components: {
    Multiselect
  },
  mixins: [ReportUtil],
  beforeCreate() {
    this.$nextTick(() => {
      this.setDefaults()
      this.getMarketShareReports()
    })
    this.$store.dispatch('setAside', true)
  },
  beforeDestroy() {
    this.clearListener()
    document.body.classList.add('aside-menu-hidden')
    this.$store.dispatch('setAsideToogle', false)
  },
  mounted() {
    this.redrawChartOnPrint()
    this.$nextTick(function() {
      this.prepareDownloadReport()
    })
    document.body.classList.remove('aside-menu-hidden')
    this.$store.dispatch('setAsideToogle', true)
    this.trackReportView()
  },
  updated() {
    this.prepareDownloadReport()
  },
  data() {
    return {
      orderSolution: false,
      userSolutionLoaded: false,
      allSolutionLoaded: false,
      allTreeData: null,
      showChart: true,
      sortOrder: {
        vendor: ['none', 'string'],
        product: ['none', 'string'],
        market_measure: ['none', 'float'],
        market_percent: ['none', 'float']
      }
    }
  },
  computed: {
    ...mapGetters(['showAside', 'isAsideOpen']),
    ...mapGetters('marketShareReport', [
      'assetMin',
      'assetMax',
      'selectedFiltersMessage',
      'reportSubTitle',
      'getTechnographicsOptions'
    ]),
    ...mapGetters('Ability', ['canPurchase', 'showTechnographics']),
    ...mapState('marketShareReport', [
      'reports',
      'solutionId',
      'reportLoading',
      'topVendors',
      'showProduct',
      'marketMeasure',
      'fiType',
      'reportData',
      'reportTotal',
      'reportOtherData',
      'topVendorsMax',
      'bankServiceName'
    ]),

    selectedSolution() {
      return this.getTechnographicsOptions
        ? this.getTechnographicsOptions.find(opt => opt.id == this.solutionId)
        : null
    },
    isSolutionSelected() {
      return this.solutionId !== undefined
    },

    regulatedTableData() {
      let regulatedData = this.reportData ? [...this.reportData] : []

      if (this.reportOtherData && this.reportData.length < this.topVendorsMax) {
        regulatedData.push(this.reportOtherData)
      }
      return regulatedData
    }
  },
  methods: {
    ...mapMutations('marketShareReport', [
      'updateSolution',
      'setDownloadReportData',
      'updateTopVendors',
      'updateFiAssetGroup',
      'updateCoreVendors',
      'setPeerGroupId',
      'setIsAdvisor',
      'updateFiType',
      'updateShowProduct'
    ]),

    ...mapActions('marketShareReport', ['getMarketShareReports']),

    setDefaults() {
      this.setPeerGroupId(null)
      this.updateSolution(134)
      this.updateCoreVendors([])
      this.updateTopVendors(15)
      this.updateShowProduct(true)
      this.updateFiAssetGroup([
        { value: '0-100000', text: '< 100,000' },
        { value: '100000-500000', text: '100,000 - 500,000' },
        { value: '500000-1000000', text: '500,000 - 1,000,000' },
        { value: '1000000-10000000', text: '1,000,000 - 10,000,000' },
        { value: '10000000-100000000', text: '10,000,000 - 100,000,000' },
        { value: '100000000-3000000000', text: '> 100,000,000' }
      ])
      this.updateFiType(0)
      this.setIsAdvisor(false)
    },
    showLink(data) {
      return (
        this.showTechnographics &&
        data.product_id !== null &&
        this.showProduct &&
        data.vendor !== 'Others'
      )
    },
    filterQuery(data) {
      let queryObj = {
        vendor_id: data.vendor_id,
        bank_service_id: this.solutionId
      }
      if (this.fiType !== 0) {
        queryObj.fi_type = this.fiType
      }
      if (this.assetMin !== null) {
        queryObj.asset_min = this.assetMin
      }
      if (this.assetMax !== null) {
        queryObj.asset_max = this.assetMax
      }
      return queryObj
    },
    updateTechnographic(selectedOption) {
      this.updateSolution(selectedOption.id)
      this.getMarketShareReports()
    },
    getMarketShare(bankId) {
      this.updateSolution(bankId)
      this.getMarketShareReports()
      this.trackReportView()
    },
    trackReportView() {
      this.$ahoy.track('report_view', {
        bank_service_id: this.solutionId || 'default',
        report_type: 'FinTech Market Reports',
        category: 'Market Share'
      })
    },
    // graphPercentage(percentage) {
    //   let max = Math.max(
    //     ...this.regulatedTableData.map(x => parseFloat(x.market_percent))
    //   )
    //   return (parseFloat(percentage) * 100) / max + '%'
    // },

    // orderNewSolution() {
    //   this.orderSolution = true
    // },
    // closeOrderSolution(status) {
    //   this.orderSolution = false
    //   if (status === 'complete') {
    //     this.userSolutionLoaded = false
    //     this.allSolutionLoaded = false
    //     this.getMarketShareReports()
    //   }
    // },

    drawChart() {
      //remove any previous charts in this page
      let charts = Highcharts.charts
      charts.forEach(function(chart) {
        if (
          chart &&
          chart.renderTo &&
          chart.renderTo.id === 'report-chart-ms'
        ) {
          chart.destroy()
        }
      })
      Highcharts.chart('report-chart-ms', {
        credits: {
          enabled: false
        },
        chart: {
          type: 'bar',
          backgroundColor: '#f4f4f4',
          height: this.chartHeight(),
          marginTop: this.marginTopValue()
        },
        title: {
          text: null
        },
        tooltip: {
          enabled: false
        },
        subtitle: {
          text: null
        },
        legend: {
          enabled: false
        },
        exporting: {
          enabled: false
        },
        plotOptions: {
          series: {
            animation: false,
            groupPadding: 0,
            dataLabels: {
              enabled: false
            }
          }
        },
        xAxis: {
          lineWidth: 0,
          tickLength: 0,
          labels: {
            enabled: false
          },
          visible: false
        },
        yAxis: {
          lineWidth: 0,
          gridLineWidth: 0,
          labels: {
            enabled: true,
            autoRotation: 0,
            style: {
              fontWeight: 'bold'
            },
            formatter: function() {
              return Vue.filter('formatValue')(this.value, 'P1')
            }
          },
          title: {
            text: null
          }
        },
        series: [
          {
            data: this.regulatedTableData.map(rd =>
              parseFloat(rd.market_percent)
            ),
            color: '#A7CA77',
            negativeColor: '#333333',
            pointPlacement: this.pointPlacementValue()
          }
        ]
      })
    },
    prepareDownloadReport() {
      let downloadReportRows = []

      //Headers
      let headers = [
        'Rank',
        'Vendor Name',
        { value: 'Market Total', format: { alignment: 'right' } },
        { value: '% of Market', format: { alignment: 'right' } }
      ]
      if (this.showProduct) headers.splice(2, 0, 'Product Name')
      downloadReportRows.push(headers)
      const combinedData = [...this.regulatedTableData, this.reportTotal]
      //Report data
      combinedData.map((row, index) => {
        let data = []
        data.push(index === combinedData.length - 1 ? '' : index + 1)
        data.push(row['vendor'])
        if (this.showProduct) {
          data.push(row['product'] ? row['product'] : '')
        }
        data.push({
          value: row['market_measure'],
          format: '#,###'
        })
        data.push({
          value: row['market_percent'],
          format: 'P1'
        })

        downloadReportRows.push(data)
      })

      this.setDownloadReportData({
        name: 'Fintech Market Reports - Market Share',
        technographic: this.selectedSolution && this.selectedSolution.name,
        subTitle: this.reportSubTitle,
        filterText: this.selectedFiltersMessage,
        data: downloadReportRows
      })
    }
  },
  watch: {
    technographic: {
      immediate: true,
      handler: function(newVal) {
        if (newVal) {
          this.solutionId = newVal.id
          this.getMarketShareReports()
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.select-icon {
  color: lightgray;
  font-size: 1em;
  padding-left: 0.25em;
  cursor: pointer;
}
.digital-channels-report {
  .market-report-header {
    .report-title {
      .multiselect {
        width: fit-content !important;
      }
      .multiselect .multiselect__content-wrapper {
        //min-width: 100% !important;
        width: fit-content !important;
        // width: auto !important;
        border-top: 1px solid #e8e8e8 !important;
      }

      .multiselect--active .multiselect__tags {
        border-bottom: none !important;
      }
      .multiselect__option {
        width: 100% !important;
      }
    }
  }
}
// .market-report-header {
//   .multiselect {
//     width: fit-content !important;
//   }

//   .multiselect__content-wrapper /*, .multiselect__element */ {
//     width: fit-content !important;
//   }

//   .multiselect__option {
//     width: 100% !important;
//   }
// }
.market-report-container {
  margin-top: 1em;
  thead tr th {
    border-bottom: 1px solid black;
  }
  tbody tr {
    line-height: 0.9em;
    td.name-column {
      white-space: nowrap;
      max-width: 18em;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .report-footer {
    border-top: 1px solid black;
    font-weight: bold;
    background-color: #f4f4f4;
  }
  td.market_percent_chart {
    padding: 0;
    width: 35%;
    .progress {
      background-color: initial;
      padding-top: 1px;
      padding-left: 3em;
      height: 3em;
    }
  }
}
</style>
