<template>
  <div class="homepage-content">
    <section class=" container-margin">
      <b-row class="about-crm-row">
        <b-col cols="4" class="px-1">
          <b-card class="about-border mb-0">
            <div slot="header">CRM Supporting Data</div>
            <b-card-body class="p-0">
              <b-row class="pb-4">
                <b-col cols="8" class="px-1">
                  <div>
                    <h5>Holding Company Structure</h5>
                    <div class="small_font">
                      Download list of all Banks/CUs with BHC structure.
                    </div>
                  </div>
                </b-col>
                <b-col cols="3" class="px-1 download_col">
                  <button
                    size=""
                    @click="downloadFile('holdingCompanyStructure')"
                    class="text-nowrap text-center cta download-btn "
                  >
                    Download
                  </button>
                </b-col>
              </b-row>
              <b-row class="pb-4">
                <b-col cols="8" class="px-1">
                  <div>
                    <h5>Merger History</h5>
                    <div class="small_font">
                      Download acquisitions by Bank/CU/BHC.
                    </div>
                  </div>
                </b-col>
                <b-col cols="3" class="px-1 download_col">
                  <button
                    size=""
                    @click="downloadFile('mergerHistory')"
                    class="text-nowrap text-center cta download-btn "
                  >
                    Download
                  </button>
                </b-col>
              </b-row>
              <b-row class="pb-4">
                <b-col cols="8" class="px-1">
                  <div>
                    <h5>All Branches w/SOD</h5>
                    <div class="small_font">
                      Download list of all branches with SOD.
                    </div>
                  </div>
                </b-col>
                <b-col cols="3" class="px-1 download_col">
                  <button
                    size=""
                    @click="downloadFile('allBranches')"
                    class="text-nowrap text-center cta download-btn "
                  >
                    Download
                  </button>
                </b-col>
              </b-row>
              <b-row class="pb-4">
                <b-col cols="8" class="px-1">
                  <div>
                    <h5>Denovo Report</h5>
                    <div class="small_font">
                      Download the recent Denovo Banks/CUs .
                    </div>
                  </div>
                </b-col>
                <b-col cols="3" class="px-1 download_col">
                  <button
                    size=""
                    @click="downloadFile('denovoReport')"
                    class="text-nowrap text-center cta download-btn "
                  >
                    Download
                  </button>
                </b-col>
              </b-row>
              <b-row class="pb-4">
                <b-col cols="8" class="px-1">
                  <div>
                    <h5>Inactive FIs</h5>
                    <div class="small_font">
                      Download all Inactive Banks/CUs.
                    </div>
                  </div>
                </b-col>
                <b-col cols="3" class="px-1 download_col">
                  <button
                    size=""
                    @click="downloadFile('inactiveFIs')"
                    class="text-nowrap text-center cta download-btn "
                  >
                    Download
                  </button>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col cols="4" class="px-1">
          <b-card class="about-border mb-0">
            <div slot="header">CRM Data Import License</div>
            <b-card-body class="p-0">
              <div class="my-2">
                CRM systems are only as good as the data housed within them.
                Give your teams a competitive advantage by enriching your CRM
                system with FI contact data, key technologies, financial
                performance, and firmographic data.
              </div>

              <div class="about-footer">
                <div class="text-center pt-4">
                  <a
                    href="https://www.fi-navigator.com/support/"
                    target="_blank"
                    class="  text-center cta w-70"
                    >Included Solution Click for More Information</a
                  >
                </div>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col cols="4" class="px-1">
          <b-card class="about-border mb-0">
            <div slot="header">Single-Click Access from your CRM</div>
            <b-card-body class="p-0">
              <div class="my-2">
                Make meeting prep more efficient and valuable with fast access
                to performance metrics, technographics, segmentation, peer
                analytics, and more. By embedding FI Navigator’s single- click
                access into your CRM Account record, users will always have the
                most advanced FI analytics at their fingertips.
              </div>

              <div class="about-footer">
                <div class="text-center pt-4">
                  <a
                    href="https://www.fi-navigator.com/support/"
                    target="_blank"
                    class=" text-center cta w-70"
                    >Included Solution Click for More Information</a
                  >
                </div>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </section>
  </div>
</template>
<script>
// global
import { mapState } from 'vuex'
import store from '@/store/store'
import router from '@/router/index'
// layout
import axios from 'axios'

export default {
  name: 'FinCrm',

  data() {
    return {
      downloadApiUrl: {
        holdingCompanyStructure:
          '/api/crm_records/download_holding_company_structure',
        mergerHistory: '/api/crm_records/download_merger_history',
        allBranches: '/api/crm_records/download_all_branches_with_deposits',
        denovoReport: '/api/crm_records/download_denovo_report',
        inactiveFIs: '/api/crm_records/download_inactive_fis'
      }
    }
  },

  beforeRouteEnter(to, from, next) {
    if (store.getters['Ability/showCrm']) {
      next()
    } else {
      router.push('/technographics/fin_query/about_crm')
    }
  },
  created() {
    if (!this.partnerSite) {
      document.title = 'FIN Query'
    }
  },
  computed: {
    ...mapState({
      partnerSite: state => state.partnerSite
    })
  },
  methods: {
    downloadFile(fileType) {
      return axios
        .get(this.downloadApiUrl[fileType], {
          handleErrors: true
        })
        .then(response => {
          this.downloadResult(
            response.data.file_url.replaceAll('\u0026', '&'),
            fileType + '.csv'
          )
        })
        .then(() => {})
    },
    downloadResult(fileUrl, fileName) {
      const link = document.createElement('a')
      link.target = '_blank'
      link.href = fileUrl
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
    }
  }
}
</script>
<style lang="scss" src="../../core/scss/infoCards.scss"></style>
<style lang="scss" scoped>
.homepage-content {
  background-size: cover;
}
</style>
